interface Keys {
  header: string,
  link: string
}

const content: Keys = {
  header: "Meet the Homeowners We've Served",
  link: 'Read stories'
};

export default content;
