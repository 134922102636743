interface Keys {
  [key: string]: any
}

const content: Keys = {
  contact: {
    info: 'Dial 311 and ask for the Center for NYC Neighborhoods',
    phone: 'Or call us directly at 646-786-0888',
    email: 'Or email us at: info@cnycn.org'
  },
  impactTitle: 'Impact Stories',
  impactLinks: [
    {
      title: 'How Saving Homes Sustains Communities',
      url: '/2018/impact/foreclosure-help'
    },
    {
      title: 'Homeowners Can Be Climate Justice Heroes',
      url: '/2018/impact/energy-efficiency-programs'
    },
    {
      title: 'How Counseling Can Make the Difference in Disaster Recovery',
      url: '/2018/impact/temporary-housing-services'
    },
    {
      title: 'Fighting for Seniors to Age in Community',
      url: '/2018/impact/helping-senior-homeowners'
    },
    {
      title: 'Helping Homeowners Where They Are',
      url: '/2018/impact/homeowner-help-desk'
    }
  ],
  aboutLinks: [
    {
      url: '/2018/about/financials',
      title: 'Financials'
    },
    {
      url: '/2018/about/staff',
      title: 'Staff'
    },
    {
      url: '/2018/about/partners',
      title: 'Partners'
    },
    {
      url: '/2018/about/funders',
      title: 'Funders'
    }
  ],
  privacy: 'Privacy Policy',
  terms: 'Terms & Conditions',
  visit: 'Visit:'
};

export default content;
