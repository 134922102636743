import React from 'react';
import Landing from './Landing';
import ImpactLanding from './ImpactLanding';
import StatsIn2019 from './StatsIn2019';
import StatsSince2008 from './StatsSince2008';
import StatsHomeowners from './StatsHomeowners';
import HighlightsLanding from './HighlightsLanding';
import ContactButton from './ContactButton';
import contactBtn from './images/contact-btn.svg';

const Home: React.FC = () => (
  <>
    <Landing />
    <ImpactLanding />
    <StatsIn2019 />
    <StatsSince2008 />
    <StatsHomeowners />
    <HighlightsLanding />
    <ContactButton button={contactBtn} />
  </>
);

export default Home;
