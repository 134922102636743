import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import content from './content/selectReport';
import logo from './images/cny-logo-nav.svg';
import styleVars from './2018/styles/variables';
import background from './2018/images/home-teal-grid-1.svg';

const Wrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  position: relative;
  background-image: url(${background});
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const Text = styled.div`
  min-height: 100%;
  color: white;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    height: 5.625em;
  }

  h1,
  h4 {
    margin: 1rem 0;
    text-align: center;
  }
`;

const Links = styled.div`
  margin: 1rem;
  padding: 1rem;
  max-width: 600px;
  text-align: left;

  > div {
    margin: 1rem;
  }

  a {
    padding: 0.5rem;
    font-size: ${styleVars.fontSizes.med};

    :hover {
      background-color: ${styleVars.colors.brandOrange};
    }
  }
`;

const SelectReport: React.FC = () => (
  <Wrapper>
    <Text>
      <img src={logo} alt="CNYCN" />
      <h1>{content.header}</h1>
      <h4>{content.select}</h4>
    </Text>
    <Links>
      <div>
        <Link to="/2019">2019</Link>
      </div>
      <div>
        <Link to="/2018">2018</Link>
      </div>
      <div>
        <a
          href="https://s28299.pcdn.co/wp-content/uploads/2018/12/CNYCN-AR2017_10.pdf"
          target="_blank"
          rel="noreferrer noopener"
        >
          2017 (pdf)
        </a>
      </div>
      <div>
        <a
          href="https://s28299.pcdn.co/wp-content/uploads/2018/03/CNYC-2016-AR_13.pdf"
          target="_blank"
          rel="noreferrer noopener"
        >
          2016 (pdf)
        </a>
      </div>
      <div>
        <a
          href="https://s28299.pcdn.co/wp-content/uploads/2014/02/CNYCN_AnnualReport_DIGITAL.pdf"
          target="_blank"
          rel="noreferrer noopener"
        >
          2015 (pdf)
        </a>
      </div>
      <div>
        <a
          href="https://s28299.pcdn.co/wp-content/uploads/2014/02/CNYCN-Annual-Report-2014-w-inserts.pdf"
          target="_blank"
          rel="noreferrer noopener"
        >
          2014 (pdf)
        </a>
      </div>
      <div>
        <a
          href="https://issuu.com/centerfornycneighborhoods/docs/cnycn_annual_report"
          target="_blank"
          rel="noreferrer noopener"
        >
          2013 (five-year report) (pdf)
        </a>
      </div>
    </Links>
  </Wrapper>
);

export default SelectReport;
