interface Keys {
  header: string,
  next: string
}

const content: Keys = {
  header: '2018 Annual Report',
  next: 'View Report'
};

export default content;
