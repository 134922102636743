import React from 'react';
import styled from 'styled-components';
import styleVars from './styles/variables';
import content from './content/about';
import TabBar from './TabBar';

const Wrapper = styled.div`
  background-color: ${styleVars.colors.brandPurple};
  color: #fff;
`;

const Header = styled.h2`
  padding-top: 5%;
  margin: 0 5%;
  border-bottom: 1px solid white;
  font-family: WhitneyCond-Light;
`;

const BodyWrapper = styled.div`
  display: flex;
  padding: 5%;
`;

const SubHeader = styled.div`
  font-family: WhitneyCond-Light;
  font-size: 4em;
`;

const StaffBoard = styled.div`
  flex: 1;
`;

const BoardUl = styled.ul`
  font-family: WhitneyCond-Light;
  list-style: none;
  padding-left: 0;
  font-size: ${styleVars.fontSizes.smallest};
`;

const BoardLi = styled.li`
  font-family: WhitneyCond-Semibold;
  font-size: ${styleVars.fontSizes.smallest};
`;

const StaffLeadership = styled.div`
  flex: 4 1 auto;
  font-family: WhitneyCond-Book;
  padding-left: 5%;
`;

const LeadershipListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  @media (max-width: ${styleVars.media.phone}) {
    justify-content: space-between;
  }
`;

const LeadershipEntry = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 8.25em;
  list-style: none;
  padding-left: 0;
  margin: 2% 5% 2% 0;
`;

const LeadershipDataBold = styled.div`
  font-family: WhitneyCond-Semibold;
  padding: 1%;
  text-align: center;
  font-size: ${styleVars.fontSizes.smallest};
`;

const LeadershipData = styled.div`
  padding: 1%;
  text-align: center;
  font-size: ${styleVars.fontSizes.smallest};

  img {
    border-radius: 50%;
    width: 7em;
    height: 7em;
  }
`;

const AboutStaff: React.FC = () => {
  const renderBoard = () => content.staff.board.map((section) => {
    const people = section.names.map((item) => (
      <BoardLi>
        {item}
      </BoardLi>
    ));
    return (
      <BoardUl>
        {section.title}
        {people}
      </BoardUl>
    );
  });

  const renderLeadership = () => content.staff.leadership.map((person) => {
    const { image, name, title } = person;
    return (
      <LeadershipEntry>
        <LeadershipData>
          <img src={image} alt="Leadership of CNYCN" />
        </LeadershipData>
        <LeadershipDataBold>
          {name}
        </LeadershipDataBold>
        <LeadershipData>
          {title}
        </LeadershipData>
      </LeadershipEntry>
    );
  });

  return (
    <>
      <TabBar styleType="about" />
      <Wrapper>
        <Header>
          {content.staff.title}
        </Header>
        <BodyWrapper>
          <StaffBoard>
            <SubHeader>
              {content.staff.subTitleBoard}
            </SubHeader>
            {renderBoard()}
          </StaffBoard>
          <StaffLeadership>
            <SubHeader>
              {content.staff.subTitleLeadership}
            </SubHeader>
            <LeadershipListWrapper>
              {renderLeadership()}
            </LeadershipListWrapper>
          </StaffLeadership>
        </BodyWrapper>
      </Wrapper>
    </>
  );
};

export default AboutStaff;
