interface Keys {
  [key: string]: string;
}

const content: Keys = {
  title: '2019 Annual Report',
  impact: 'Highlights',
  financials: 'Financials',
  staff: 'Staff',
  share: 'Share:'
};

export default content;
