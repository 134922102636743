import React from 'react';
import styled from 'styled-components';
import styleVars from './styles/variables';
import content from './content/about';
import TabBar from './TabBar';

const Wrapper = styled.div`
  background-color: ${styleVars.colors.brandPurple};
  color: #fff;
`;

const Header = styled.h2`
  padding-top: 5%;
  margin: 0 5%;
  border-bottom: 1px solid white;
  font-family: WhitneyCond-Light;
`;

const BodyWrapper = styled.div`
  display: flex;
  padding: 5%;

  @media (max-width: ${styleVars.media.phone}) {
    flex-direction: column;
  }
`;

const FundersDescription = styled.div`
  flex: 1;
  font-family: WhitneyCond-Semibold;
  line-height: 1.7;
  margin: 0 5% 7% 0;
  font-size: ${styleVars.fontSizes.smaller};

  @media (max-width: ${styleVars.media.phone}) {
    font-size: ${styleVars.fontSizes.small};
  }
`;

const FunderList = styled.ul`
  flex: 3;
  display: flex;
  flex-flow: column wrap;
  list-style: none;
  padding-left: 0;
  margin: 0;
  height: 120vh;
  font-family: WhitneyCond-Book;
  font-size: ${styleVars.fontSizes.smallest};

  @media (max-width: ${styleVars.media.phone}) {
    height: auto;
  }
`;

const FunderListItem = styled.li`
  padding-bottom: 2%;
  max-width: 200px;

  @media (max-width: ${styleVars.media.phone}) {
    font-size: ${styleVars.fontSizes.medSmall};
    max-width: none;
  }
`;

const AboutFunders: React.FC = () => {
  const renderFunders = () => content.funders.funderList.map((funder) => (
    <FunderListItem>
      {funder}
    </FunderListItem>
  ));

  return (
    <>
      <TabBar styleType="about" />
      <Wrapper>
        <Header>
          {content.funders.title}
        </Header>
        <BodyWrapper>
          <FundersDescription>
            {content.funders.subHeader}
          </FundersDescription>
          <FunderList>
            {renderFunders()}
          </FunderList>
        </BodyWrapper>
      </Wrapper>
    </>
  );
};

export default AboutFunders;
