import React, { useState } from 'react';
import { Normalize } from 'styled-normalize';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import GlobalStyles from './styles/globalStyles';
import ContactModal from './ContactModal';
import NavBar from './NavBar';
import Footer from './Footer';
import ScrollToTop from './ScrollToTop';
import AppRouter from './AppRouter';
import ModalContext from './context';

const App: React.FC = () => {
  document.title = 'CNYCN Annual Report';
  const [modalActive, setModalActive] = useState(false);
  ReactGA.initialize('UA-155596519-1');
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <>
      <ModalContext.Provider value={{ modalActive, setModalActive }}>
        <Normalize />
        <GlobalStyles />
        <BrowserRouter>
          <ScrollToTop />
          <ContactModal />
          <NavBar />
          <AppRouter />
          <Footer />
        </BrowserRouter>
      </ModalContext.Provider>
    </>
  );
};

export default App;
