import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import styleVars from './styles/variables';
import content from './content/tabBar';

const Tab = styled.nav`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
`;

const activeTab = 'nav-item-active';

const TabLink = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: 25px;
  border-right: 1px solid white;
  line-height: 1.4em;
  font-family: Whitney-SemiBold;
  font-size: ${styleVars.fontSizes.smallest};
  background-color: ${styleVars.colors.darkBlue};
  text-align: center;
  color: #fff;
  transition: 0.2s all ease-out;
  padding: 2%;

  &:hover {
    background-color: ${styleVars.colors.blue};
  }

  &.${activeTab} {
    background-color: ${styleVars.colors.blue};
  }

  &:last-child {
    border-right: none;
  }

  @media (max-width: ${styleVars.media.tablet}) {
    justify-content: center;
    width: auto;
  }
`;

const AboutTabBar: React.FC = () => {
  const renderAboutTabs = content.about.map((tab: any) => (
    <TabLink activeClassName={activeTab} to={tab.url}>
      {tab.title}
    </TabLink>
  ));

  return <Tab>{renderAboutTabs}</Tab>;
};

export default AboutTabBar;
