import React from 'react';
import styled from 'styled-components';
import styleVars from './styles/variables';

const PullQuoteStyling = styled.div`
  padding-top: 5%;
  padding-left: 15%;
  padding-right: 15%;
  color: ${styleVars.colors.brandTeal};
  font-size: 2.5rem;
  font-family: WhitneyCond-Semibold;

  @media (max-width: ${styleVars.media.desktop}) {
    padding-right: 5%;
    padding-left: 5%;
    font-size: 3rem;
  }
`;

const PullQuote: React.FC<{content: string}> = ({ content }) => (
  <PullQuoteStyling>
    {content}
  </PullQuoteStyling>
);

export default PullQuote;
