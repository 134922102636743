import React from 'react';
import styled from 'styled-components';
import WindowSizedWrapper from './WindowSizedWrapper';
import content from './content/impactStoryLanding';
import styleVars from './styles/variables';
import background from './images/cover-image-bottom.png';

const ContentWrapper = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  text-shadow: 3px 3px 3px #555;
  padding: 0 3%;
`;

const TextWrapper = styled.div`
  text-align: left;
  position: relative;
  top: -1rem;

  @media (min-width: ${styleVars.media.tablet}) {
    left: -4rem;
  }
`;

const Header = styled.h3`
  max-width: 450px;
  margin: 2rem 0;

  @media (max-width: ${styleVars.media.phone}) {
    margin-bottom: 2rem;
  }
`;

const StoriesLink = styled.a`
  text-shadow: 2px 2px 2px #555;
  font-size: ${styleVars.fontSizes.small};
  text-decoration: underline;

  @media (max-width: ${styleVars.media.phone}) {
    font-size: ${styleVars.fontSizes.med};
  }
`;

const ImpactStoryLanding: React.FC = () => (
  <WindowSizedWrapper
    id="to-stories"
    backgroundUrl={background}
    backgroundPosition="center"
  >
    <ContentWrapper>
      <TextWrapper>
        <Header>{content.header}</Header>
        <StoriesLink href="/2018/impact/foreclosure-help">
          {content.link} &rarr;
        </StoriesLink>
      </TextWrapper>
    </ContentWrapper>
  </WindowSizedWrapper>
);

export default ImpactStoryLanding;
