import React from 'react';
import styled from 'styled-components';
import WindowSizedWrapper from './WindowSizedWrapper';
import NextSection from './NextSection';
import content from './content/landing';
import logo from './images/cny-logo-nav.svg';
import background from './images/cover-image-top.png';

const CenterContent = styled.div`
  min-height: 100%;
  color: white;
  text-shadow: 3px 3px 3px #555;

  img {
    height: 5.625em;
  }
`;

const Header = styled.h1`
  padding-top: 3%;
  padding-bottom: 2%;
`;

const Landing: React.FC = () => (
  <WindowSizedWrapper
    backgroundUrl={background}
    backgroundPosition="right"
  >
    <CenterContent>
      <img src={logo} alt="CNYCN" />
      <Header>{content.header}</Header>
    </CenterContent>
    <NextSection text={content.next} nextId="impact" />
  </WindowSizedWrapper>
);

export default Landing;
