import React from 'react';
import styled from 'styled-components';
import StatsHeader from './StatsHeader';
import Datapoint from './Datapoint';
import NextSection from './NextSection';
import content from './content/statsHomeowners';
import background from './images/home-blue-grid-2.svg';
import styleVars from './styles/variables';

const ContentWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;

  * {
    box-sizing: border-box;
  }
`;

const StatsWrapper = styled.div`
  width: 90%;
  display: grid;
  grid-gap: 1.5rem;
  margin-bottom: 6rem;
  grid-auto-columns: 1fr;
  grid-template-areas:
    'graph1 . graph2'
    'graph1 . graph2'
    'graph1 . graph2';

  @media (max-width: 1000px) {
    grid-template-areas: unset;
    grid-template-rows: minmax(180px, 1fr) auto auto;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 500px) {
    grid-template-rows: repeat(3, minmax(180px, 1fr)) auto auto;
    grid-template-columns: 1fr;
  }
`;

// prettier-ignore
const Graph = styled.div<{ idx: number }>`
  background-color: white;
  color: ${styleVars.colors.darkBlue};
  display: flex;
  font-size: 1.5rem;
  padding: 5%;
  align-items: center;
  grid-area: graph${(props) => props.idx + 1};
  flex-flow: column nowrap;
  filter: drop-shadow(0 0 11px rgba(0, 0, 0, 0.08));

  @media (max-width: 1000px) {
    grid-area: unset;
    grid-column: span 3;
    flex-flow: row nowrap;
  }

  @media (max-width: 500px) {
    flex-flow: column nowrap;
    grid-column: span 1;
  }

  img {
    max-height: 250px;
    max-width: 250px;
  }
`;

const GraphData = styled.div`
  @media (max-width: 1000px) {
    margin-left: 3rem;
  }

  @media (max-width: 500px) {
    margin-left: 0;
  }
`;

const GraphTitle = styled.div`
  font-family: WhitneyCond-Bold;
  font-size: 2rem;
  text-align: center;
  margin: 1rem 0;

  @media (max-width: 1000px) and (min-width: 501px) {
    text-align: left;
  }
`;

const LegendItem = styled.div<{ color: string }>`
  font-family: Whitney-Light;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  span:first-of-type {
    background-color: ${(props) => props.color};
    height: 1rem;
    width: 1.5rem;
    flex-shrink: 0;
  }

  span {
    margin: 0.1rem 0.25rem;
  }
`;

const StatsHomeowners: React.FC = () => (
  <ContentWrapper id="stats-homeowners">
    <StatsHeader title={content.title} />
    <StatsWrapper>
      {content.datapoints.map((data) => (
        <Datapoint data={data} fontColor={styleVars.colors.darkBlue} />
      ))}
      {content.graphs.map((graph, idx) => (
        <Graph idx={idx}>
          <img src={graph.graph} alt="graph" />
          <GraphData>
            <GraphTitle>{graph.title}</GraphTitle>
            {graph.stats.map((stat) => (
              <LegendItem color={stat.color}>
                <span />
                <span>{stat.percentage}</span>
                <span>{stat.text}</span>
              </LegendItem>
            ))}
          </GraphData>
        </Graph>
      ))}
    </StatsWrapper>
    <NextSection
      text={content.next}
      nextId="to-stories"
      color="blue"
      relativePosition
    />
  </ContentWrapper>
);

export default StatsHomeowners;
