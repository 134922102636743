import dollar from '../images/dollar-blue.svg';
import house from '../images/house-blue.svg';

interface Datapoint {
  stat: string;
  description: string;
  icon: {
    src: string;
    alt: string;
  };
}

interface Keys {
  title: string;
  datapoints: Array<Datapoint>;
  next: string;
  image: {
    alt: string;
  };
}

const datapoints = [
  {
    stat: '$133M+',
    description: 'total savings for homeowners',
    icon: {
      src: dollar,
      alt: 'dollar sign'
    }
  },
  {
    stat: '19,118',
    description: 'homes saved',
    icon: {
      src: house,
      alt: 'house icon'
    }
  },
  {
    stat: '$10B+',
    description: 'of property value preserved',
    icon: {
      src: dollar,
      alt: 'dollar sign'
    }
  }
];

const content: Keys = {
  title: 'From 2008 to the present',
  datapoints,
  next: 'Homeowners Served',
  image: {
    alt: 'Picture of family'
  }
};

export default content;
