import ethnicitySVG from '../images/pie-chart-race.svg';
import boroughSVG from '../images/pie-chart-borough.svg';
import styleVars from '../styles/variables';

interface Datapoint {
  stat: string,
  description: string
}

interface GraphDatapoint {
  color: string,
  percentage: string,
  text: string
}

interface Graph {
  graph: string,
  title: string,
  stats: Array<GraphDatapoint>
}

interface Keys {
  title: string,
  datapoints: Array<Datapoint>,
  next: string,
  graphs: Array<Graph>,
}

const datapoints = [
  {
    stat: '$38,000',
    description: 'Average household income of families served'
  },
  {
    stat: '66,792',
    description: 'Connections made to high-quality free services'
  },
  {
    stat: '8',
    description: 'Number of languages spoken by Homeowner Hub'
  }
];

const ethnicityGraph = {
  graph: ethnicitySVG,
  title: 'RACE AND ETHNICITY',
  stats: [
    {
      color: styleVars.colors.brandBlue,
      percentage: '3%',
      text: 'Asian'
    },
    {
      color: styleVars.colors.brandOrange,
      percentage: '38%',
      text: 'Black'
    },
    {
      color: styleVars.colors.brandTeal,
      percentage: '0.40%',
      text: 'Native American'
    },
    {
      color: styleVars.colors.gray,
      percentage: '0.10%',
      text: 'Hawaiian/Pacific Islander'
    },
    {
      color: styleVars.colors.brandYellow,
      percentage: '12%',
      text: 'Hispanic'
    },
    {
      color: styleVars.colors.brandGreen,
      percentage: '38%',
      text: 'White'
    },
    {
      color: styleVars.colors.brandPurple,
      percentage: '8%',
      text: 'Other/Multiracial'
    }
  ]
};

const boroughGraph = {
  graph: boroughSVG,
  title: 'SERVED BY BOROUGH',
  stats: [
    {
      color: styleVars.colors.brandOrange,
      percentage: '41.15%',
      text: 'Brooklyn'
    },
    {
      color: styleVars.colors.brandYellow,
      percentage: '36.23%',
      text: 'Queens'
    },
    {
      color: styleVars.colors.brandPurple,
      percentage: '8.2%',
      text: 'Staten Island'
    },
    {
      color: styleVars.colors.brandBlue,
      percentage: '3.17%',
      text: 'Manhattan'
    },
    {
      color: styleVars.colors.brandGreen,
      percentage: '11.26%',
      text: 'Bronx'
    }
  ]
};

const content: Keys = {
  title: 'Homeowners Served',
  datapoints,
  next: 'Meet Our Homeowners',
  graphs: [ethnicityGraph, boroughGraph]
};

export default content;
