interface Keys {
  header: Array<string>,
  next: string
}

const content: Keys = {
  header: ['Since our founding in 2008, we have helped over ', '218,000 homeowners', ' across New York.'],
  next: 'See Our Impact'
};

export default content;
