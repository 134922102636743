import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import styleVars from './styles/variables';
import Hamburger from './images/hamburger.svg';
import Close from './images/exit.svg';
import NavLinks from './NavLinks';

const Wrapper = styled.div`
  @media (min-width: 601px) {
    display: none;
  }
`;

const MenuWrapper = styled.div<{ show: boolean }>`
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: fixed;
  z-index: 3;
  box-shadow: -1px 0 5px #555;
  height: 100vh;
  width: 60%;
  transform: translateX(${(props) => (props.show ? '-100%' : '10%')});
  top: 0;
  background-color: white;
  transition: transform 0.3s ease;
`;

const OpenButton = styled.img`
  top: 5px;
  right: 0.75em;
  position: absolute;
  height: 40px;
  width: 50px;
`;

const CloseButton = styled(OpenButton)`
  z-index: 3;
`;

const MobileMenu = () => {
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(() => false);
  }, [location]);

  return (
    <Wrapper>
      <OpenButton
        src={Hamburger}
        alt="Hamburger menu"
        onClick={() => setIsVisible(() => true)}
      />
      <MenuWrapper show={isVisible}>
        <CloseButton
          src={Close}
          alt="Close button"
          onClick={() => setIsVisible(() => false)}
        />
        <NavLinks />
      </MenuWrapper>
    </Wrapper>
  );
};

export default MobileMenu;
