import React from 'react';
import styled from 'styled-components';
import background from './images/terms-blue-grid.svg';
import content from './content/termsAndConditions';
import styleVars from './styles/variables';

const BackgroundWrapper = styled.div`
  background-image: url(${background});
  background-size: cover;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: fit-content;
  display: flex;
`;

const TermsWrapper = styled.div`
  padding: 10% 25%;

  @media (max-width: ${styleVars.media.tablet}) {
    padding: 25% 10%;
  }
`;

const TermsHeader = styled.h4`
  color: white;
`;

const TermsSubHeader = styled.div`
  padding-top: 20px;
  color: white;
  font-size: ${styleVars.fontSizes.small};
`;

const TermsBody = styled.div`
  padding-top: 20px;
  color: white;
  font: Whitney-Book;
  line-height: 1.4;
`;

const TermsAndConditions: React.FC = () => (
  <BackgroundWrapper>
    <TermsWrapper>
      <TermsHeader>
        {content.header}
      </TermsHeader>
      <TermsSubHeader dangerouslySetInnerHTML={{ __html: content.subHeader }} />
      <TermsBody dangerouslySetInnerHTML={{ __html: content.body }} />
    </TermsWrapper>
  </BackgroundWrapper>
);

export default TermsAndConditions;
