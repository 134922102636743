import React from 'react';
import styled from 'styled-components';
import Datapoint from './Datapoint';
import NextSection from './NextSection';
import content from './content/statsIn2018';
import background from './images/home-blue-grid-1.svg';
import styleVars from './styles/variables';
import image from './images/stats-2018.png';

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  background-image: url(${background});
  background-size: cover;
  background-position: right;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding-bottom: 2rem;

  * {
    box-sizing: border-box;
  }
`;

const Header = styled.h3`
  color: white;
  text-align: center;
  font-family: WhitneyCond-Semibold;
  margin: 5rem 0;

  span {
    width: fit-content;
    padding: 0.25rem;
    background-color: ${styleVars.colors.brandOrange};
  }
`;

const StatsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 300px) 400px;
  grid-template-rows: 1fr 1fr;
  grid-gap: 2rem;
  justify-content: center;
  grid-template-areas:
    ". . . image image"
    ". . . image image";
  width: 90%;
  margin-bottom: 6rem;

  @media(max-width: 1500px) {
    grid-template-areas: unset;
    grid-auto-flow: row dense;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(3, minmax(250px, 350px));
  }

  @media(max-width: 1100px) {
    grid-template-columns: repeat(2, minmax(150px, 300px));
  }

  @media(max-width: 350px) {
    grid-template-columns: 1fr;
  }
`;

const Image = styled.img`
  width: 100%;
  align-self: center;
  justify-self: center;
  grid-area: image;

  @media(max-width: 1500px) {
    grid-area: 1 / 2 / span 3 / span 2;
  }

  @media(max-width: 1100px) {
    grid-area: initial;
    align-self: start;
    grid-row-end: span 4;
    grid-column-end: span 2;
  }

  @media(max-width: 500px) {
    grid-row-end: span 2;
  }

  @media(max-width: 350px) {
    grid-column-end: initial;
    grid-row-end: span 2;
  }
`;

const StatsIn2018: React.FC = () => (
  <Wrapper id="stats-2018">
    <Header>
      <span>
        {content.title}
      </span>
    </Header>
    <StatsWrapper>
      {content.datapoints.map((data) => (
        <Datapoint
          data={data}
          fontColor={styleVars.colors.darkBlue}
        />
      ))}
      <Image alt={content.image.alt} src={image} />
    </StatsWrapper>
    <NextSection text={content.next} nextId="stats-2008" relativePosition />
  </Wrapper>
);

export default StatsIn2018;
