import familyOnStoop from '../images/highlights/family-on-stoop-1.jpg';
import brownstones from '../images/highlights/brownstones.jpg';
import twoFamilies from '../images/highlights/two-families.jpg';
import nyTimes from '../images/highlights/ny-times.jpg';

export interface Story {
  title: string;
  heroImage: string;
  subheading?: string;
  paragraphs: Array<string>;
  callOut?: Array<string>;
  link?: string;
}

interface Stories {
  [key: string]: Story;
}

const content: Stories = {
  storyOne: {
    title: 'Leading with Systems Change and Advocacy',
    heroImage: familyOnStoop,
    subheading: 'In 2019:',
    paragraphs: [
      'At the Center, we believe that a reinforcing cycle of research, advocacy, thought leadership, pilot programs, and technical assistance make the strongest case for governmental changes and investments to stabilize homeowners and expand affordable homeownership.',
      'This is why data and policy are a core part of the Center’s approach: research and advocacy mean we can support homeowners where and when the need is greatest, and we can advocate to elected officials for policies that support and uplift homeowners.',
      'Our research is driven by data and by experience on the ground with homeowners, and analyzes past and current trends to shed light on real needs and make recommendations on best practices and policies.'
    ],
    callOut: [
      'We released our groundbreaking Equitable Homeownership Blueprint. The blueprint outlines the Center’s vision for the future, where homeownership is a truly viable choice for working New Yorkers, intergenerational prosperity is possible for low- and moderate-income families, and new models of shared equity thrive. The blueprint outlines goals for Community Equity and Justice, Strong, Healthy Homes, Financial Strength, Aging in Community, and Climate Justice.',
      'We held our fifth Affordable Homeownership Summit in September, featuring a keynote by leading analyst and news commentator Errol Louis, and panels focusing on federal and state policy, fintech regulation, the Green New Deal, Black and Latinx homeownership, homes and health, and supports for seniors. 230+ attendees joined us to engage with speakers from organizations like the Urban Institute and National Consumer Law Center, state and city elected officials, practitioners from other cities, and leaders in New York City homeownership policy.'
    ]
  },
  storyTwo: {
    title: 'Equity and Innovation in Lending',
    heroImage: brownstones,
    subheading: 'In 2019:',
    paragraphs: [
      'Sustainable Neighborhoods LLC, a Community Development Financial Institution (CDFI) wholly-owned by the Center, develops innovative affordable lending products for low- and moderate-income families so that they can keep their homes, build assets, and sustain intergenerational wealth.',
      'We pilot and power new financial opportunities for working families and existing homeowners, servicing those who are not generally served by traditional capital or banking outlets. With reasonably priced mortgage loans, responsible servicing, and effective mortgage counseling, homeowners can avoid foreclosure, rebuild their credit, and begin building wealth.'
    ],
    callOut: [
      'Sustainable Neighborhoods concluded the New York State Mortgage Assistance Program (NYS-MAP), created to provide one-time relief to homeowners at risk of losing their homes to foreclosure in the form of zero-interest loans.',
      'Between 2016 and 2019, NYS-MAP distributed $80M in foreclosure prevention funding to homeowners, saving over 2,000 homes and families.',
      'Saving individual homes saves communities: we leveraged an initial $80M investment 12 times over to preserve more than $1 Billion in equity',
      'Borrowers in distress can be loaned to securely and reliably: NYS-MAP proves that lending to distressed families can be done successfully, and borrowers in foreclosure should have equitable access to capital.',
      'Housing counseling & legal services are effective: funding organizations on the ground makes other investments more efficient as homeowners get the tailored care they need.',
      'Localities & courts listen to MAP: strength of outcomes led them to change processes to protect homeowners.'
    ]
  },
  storyThree: {
    title: 'Helping Homeowners Where They Are',
    heroImage: twoFamilies,
    subheading: 'In 2019:',
    paragraphs: [
      'Our Homeowner Hub, reachable online and via phone through 311 or directly, serves as a streamlined single point of entry into our homeowner stabilization programs. More than just a simple call center, the Hub triages pressing issues, makes top quality referrals, and provides case management services, with a focus on providing a superior homeowner experience.',
      'Our associates are skilled in handling difficult conversations and are knowledgeable about a wide range of tenant and homeowner issues. We customize all referrals by service need, preferred spoken language, geography, and homeowner preference, and more.'
    ],
    callOut: [
      'The Homeowner Hub received over 20,000 telephone calls and engaged with 6,725 unique callers throughout New York State, spending 1,300+ hours on the telephone, and making over 3,400 referrals for in-depth services.',
      'Average call time was 5.5 minutes, but can range from a brief call to 20 minutes of detailed discussion.',
      'The Hub can serve New Yorkers in their native languages of Spanish, Mandarin, Cantonese, and all dialects of Arabic.'
    ]
  },
  storyFour: {
    title: 'Deed Scams Spotlighted in the NY Times',
    heroImage: nyTimes,
    paragraphs: [
      'At the Center, our goal is to support and assist low- and moderate-income homeowners in every aspect of homeownership. This includes raising awareness of issues they may face, especially critical when it comes to deed theft and other scams. In 2019, homeowner Broadie Byas’ story was featured in The New York Times with the Center’s help, shining a light on how unscrupulous operators will target homeowners, especially in communities of color, to steal their homes out from under them.'
    ],
    link: 'Visit NYTimes to Read the Article'
  }
};

export default content;
