import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import styleVars from './styles/variables';
import content from './content/tabBar';

const Tab = styled.nav`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);

  @media (max-width: ${styleVars.media.tablet}) {
    padding: 4% 0 4% 5%;
    gap: 5%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  @media (max-width: ${styleVars.media.phone}) {
    padding: 10% 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
`;

const TabNumber = styled.div`
  font-family: Whitney-SemiBold;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s all ease-out;
  background-color: ${styleVars.colors.blue};
  color: #fff;
  height: 40px;
  padding-left: 13px;
  padding-right: 13px;

  @media (max-width: ${styleVars.media.tablet}) {
    font-size: ${styleVars.fontSizes.smallest};
    flex: 0 1 10px;
  }

  @media (max-width: ${styleVars.media.phone}) {
    font-size: ${styleVars.fontSizes.smallest};
    align-self: center;
  }
`;

const activeTab = 'nav-item-active';

const TabLink = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-family: 'Whitney-Light';
  font-size: ${styleVars.fontSizes.smallest};
  background-color: #fff;
  text-align: center;
  color: #000;
  transition: 0.2s all ease-out;
  padding: 2%;

  &:hover {
    background-color: ${styleVars.colors.lightBlue};
  }

  &.${activeTab} {
    color: #fff;
    background-color: ${styleVars.colors.blue};

    ${TabNumber} {
      background-color: #fff;
      color: ${styleVars.colors.blue};
    }
  }

  @media (max-width: ${styleVars.media.tablet}) {
    justify-content: center;
  }
`;

const TabTitle = styled.div`
  text-align: left;
  flex: 0 1 200px;

  @media (max-width: ${styleVars.media.tablet}) {
    font-size: ${styleVars.fontSizes.smallest};
    width: 350px;
    flex: 1;
  }
`;

const TabWrapper = styled.div`
  gap: 5%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: ${styleVars.media.tablet}) {
    align-items: center;
  }

  @media (max-width: ${styleVars.media.phone}) {
    font-size: ${styleVars.fontSizes.smallest};
    max-width: 225px;
  }
`;

const TabBar: React.FC = () => {
  const renderImpactTabs = content.impact.map((tab: any, index: number) => (
    <TabLink activeClassName={activeTab} to={tab.url}>
      <TabWrapper>
        <TabNumber>{index + 1}</TabNumber>
        <TabTitle>{tab.title}</TabTitle>
      </TabWrapper>
    </TabLink>
  ));

  return <Tab>{renderImpactTabs}</Tab>;
};

export default TabBar;
