import { createGlobalStyle } from 'styled-components/macro';
import styleVars from './styles/variables';
import WhitneyBold from '../styles/fonts/Whitney-Bold.otf';
import WhitneyLight from '../styles/fonts/Whitney-Light.otf';
import WhitneyBook from '../styles/fonts/Whitney-Book.otf';
import WhitneySemibold from '../styles/fonts/Whitney-Semibold.otf';
import WhitneyCondBold from '../styles/fonts/WhitneyCond-Bold.otf';
import WhitneyCondLight from '../styles/fonts/WhitneyCond-Light.otf';
import WhitneyCondBook from '../styles/fonts/WhitneyCond-Book.otf';
import WhitneyCondSemibold from '../styles/fonts/WhitneyCond-Semibold.otf';

// All default styles go in here
// TODO update default styles
export default createGlobalStyle`
  html {
    font-size: 16px;
    font-family: Whitney-Book;
    box-sizing: border-box;
    scroll-behavior: smooth;

    @media (max-width: 850px) {
      font-size: 12px;
    }

    @media (max-width: 600px) {
      font-size: 10px;
    }
  }

  body {
    margin: 0;
    padding: 0;
  }

  nav {
    color: #fff;
  }

  h1 {
    font-family: 'Whitney-Bold';
    font-size: ${styleVars.fontSizes.largest};
    margin: 0;
  }

  h2 {
    font-size: ${styleVars.fontSizes.larger};
    margin: 0;
  }

  h3 {
    font-family: 'Whitney-Bold';
    font-size: ${styleVars.fontSizes.larger};
    margin: 0;
  }

  h4 {
    font-family: 'Whitney-Bold';
    font-size: ${styleVars.fontSizes.medSmall};
    margin: 0;
  }

  button {
    border: 1px solid white;
    background-color: ${styleVars.colors.brandPurple};
    color: white;
    width: 100px;
    height: 50px;
    outline: none;

    &.selected {
      border: 1px solid white;
      background-color: white;
      color: ${styleVars.colors.brandPurple};
    }
  }

  a {
    color: #fff;
    text-decoration: none;
    font-family: 'Whitney-Book';
    -webkit-tap-highlight-color: transparent;
  }

  footer {
    max-width: 100%;
    padding: 5% 5% 2% 5%;
  }

  @font-face {
    font-family: 'Whitney-Bold';
    src: url(${WhitneyBold}) format('opentype');
  }

  @font-face {
    font-family: 'Whitney-Light';
    src: url(${WhitneyLight}) format('opentype');
  }

  @font-face {
    font-family: 'Whitney-Book';
    src: url(${WhitneyBook}) format('opentype');
  }

  @font-face {
    font-family: 'Whitney-Semibold';
    src: url(${WhitneySemibold}) format('opentype');
  }

  @font-face {
    font-family: 'WhitneyCond-Semibold';
    src: url(${WhitneyCondSemibold}) format('opentype');
  }

  @font-face {
    font-family: 'WhitneyCond-Bold';
    src: url(${WhitneyCondBold}) format('opentype');
  }

  @font-face {
    font-family: 'WhitneyCond-Book';
    src: url(${WhitneyCondBook}) format('opentype');
  }

  @font-face {
    font-family: 'WhitneyCond-Light';
    src: url(${WhitneyCondLight}) format('opentype');
  }
`;
