import React from 'react';
import styled from 'styled-components';
import dropdown from './images/dropdown.svg';

interface Props {
  text: string,
  nextId: string,
  relativePosition?: boolean
}

const ContentWrapper = styled.div<{relativePosition: boolean}>`
  position: ${(props) => (props.relativePosition ? 'relative' : 'absolute')};
  bottom: 3%;
  left: 0;
  right: 0;
  color: white;
  margin: 0 auto;

  a {
    margin: 0 auto;
    width: fit-content;
    font-size: 1.5em;
    text-decoration: none;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: WhitneyCond-Semibold;

    div {
      padding-bottom: 15px;
      text-shadow: 3px 3px 3px #555;
    }

    img {
      height: 17px;
    }
  }
`;

const NextSection: React.FC<Props> = (
  { text, nextId, relativePosition }
) => (
  <ContentWrapper relativePosition={relativePosition || false}>
    <a href={`#${nextId}`}>
      <div>{text}</div>
      <img src={dropdown} alt="down arrow" />
    </a>
  </ContentWrapper>
);

export default NextSection;
