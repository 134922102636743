import React from 'react';
import styled from 'styled-components';

interface Icon {
  src: string,
  alt: string
}

interface Props {
  data: {
    stat: string,
    description: string,
    icon?: Icon
  },
  fontColor: string
}

interface ContentWrapperProps {
  hasIcon: boolean,
  fontColor: string
}

const ContentWrapper = styled.div<ContentWrapperProps>`
  background-color: white;
  padding: 0 1rem;
  color: ${(props) => props.fontColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const Stat = styled.div`
  font-size: 4rem;
  font-family: WhitneyCond-Bold;
`;

const Description = styled.div`
  font-size: 1.75rem;
  font-family: Whitney-Book;
`;

const Icon = styled.img`
  margin-top: 2vw;
  height: 30%;

  @media(max-width: 700px) {
    display: none;
  }
`;

const Datapoint: React.FC<Props> = ({ data, fontColor }) => (
  <ContentWrapper fontColor={fontColor} hasIcon={!!data.icon}>
    <Stat>{data.stat}</Stat>
    <Description>{data.description}</Description>
    {data.icon && <Icon src={data.icon.src} alt={data.icon.alt} />}
  </ContentWrapper>
);

export default Datapoint;
