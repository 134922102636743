import React, { useState } from 'react';
import { Normalize } from 'styled-normalize';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import GlobalStyles from './globalStyles';
import AppRouter from './AppRouter';
import GlobalFooter from './GlobalFooter';
import ModalContext from './context';

const App: React.FC = () => {
  document.title = 'CNYCN Annual Report';
  const [modalActive, setModalActive] = useState(false);
  ReactGA.initialize('UA-155596519-1');
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <>
      <ModalContext.Provider value={{ modalActive, setModalActive }}>
        <Normalize />
        <GlobalStyles />
        <BrowserRouter>
          <AppRouter />
          <GlobalFooter />
        </BrowserRouter>
      </ModalContext.Provider>
    </>
  );
};

export default App;
