import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './Home';
import AboutFinancials from './AboutFinancials';
import AboutStaff from './AboutStaff';
import AboutPartners from './AboutPartners';
import AboutFunders from './AboutFunders';
import HighlightStories from './HighlightStories';

const AppRouter: React.FC = () => (
  <Switch>
    <Route exact path="/2019">
      <Home />
    </Route>
    <Route path="/2019/about/financials">
      <AboutFinancials />
    </Route>
    <Route path="/2019/about/staff">
      <AboutStaff />
    </Route>
    <Route path="/2019/about/partners">
      <AboutPartners />
    </Route>
    <Route path="/2019/about/funders">
      <AboutFunders />
    </Route>
    <Route path="/2019/highlights/change-and-advocacy">
      <HighlightStories story="storyOne" />
    </Route>
    <Route path="/2019/highlights/equity-innovatiation-lending">
      <HighlightStories story="storyTwo" />
    </Route>
    <Route path="/2019/highlights/helping-homeowners">
      <HighlightStories story="storyThree" />
    </Route>
    <Route path="/2019/highlights/deed-scams-spotlight">
      <HighlightStories story="storyFour" />
    </Route>
  </Switch>
);

export default AppRouter;
