import React from 'react';

interface IModal {
  modalActive: boolean;
  setModalActive: Function;
}

const ModalContext = React.createContext<IModal>({
  modalActive: false,
  setModalActive: () => {}
});

export default ModalContext;
