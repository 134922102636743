interface Keys {
  header: Array<string>;
  next: string;
}

const content: Keys = {
  header: [
    'In 2019, we reached over ',
    '330,000 homeowners',
    ' across New York.'
  ],
  next: 'See Our Impact'
};

export default content;
