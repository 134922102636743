import React from 'react';
import { Link, useLocation, matchPath } from 'react-router-dom';
import styled from 'styled-components';
import styleVars from './styles/variables';
import content from './content/footer';
import Logo from './images/CNYCN-logo.svg';
import Twitter from './images/social-icon-twitter-white.svg';
import Facebook from './images/social-icon-facebook-white.svg';
import Instagram from './images/social-icon-instagram-white.svg';
import LogoBlue from './images/CNYCN-logo-financial.svg';
import TwitterBlue from './images/social-icon-twitter.svg';
import FacebookBlue from './images/social-icon-facebook.svg';
import InstagramBlue from './images/social-icon-instagram.svg';

const FooterDiv = styled.div<{ isStyleInverted: boolean }>`
  display: flex;
  font-family: Whitney-Book;
  background-color: ${(props) =>
    props.isStyleInverted ? '#fff' : styleVars.colors.blue};
  color: ${(props) => (props.isStyleInverted ? styleVars.colors.blue : '#fff')};

  @media (max-width: ${styleVars.media.phone}) {
    padding: 10%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`;

const FooterContent = styled.div`
  padding: 4%;
  display: flex;
  justify-content: space-around;
  flex: 2;
  border-right: 1px solid ${styleVars.colors.lightBlue};

  @media (max-width: ${styleVars.media.tablet}) {
    font-size: ${styleVars.fontSizes.smaller};
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
  }

  @media (max-width: ${styleVars.media.phone}) {
    padding: 5% 0;
    font-size: ${styleVars.fontSizes.small};
    order: 1;
    border-right: 0;
  }
`;

const FooterContentStories = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2%;
  padding-right: 5%;
`;

const FooterStoryHeader = styled.div<{ isStyleInverted: boolean }>`
  font-family: Whitney-Semibold;
  font-size: ${styleVars.fontSizes.smaller};
  color: ${(props) => (props.isStyleInverted ? styleVars.colors.blue : '#fff')};

  @media (max-width: ${styleVars.media.tablet}) {
    display: none;
  }
`;

const FooterContentLinks = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 5%;

  @media (max-width: ${styleVars.media.tablet}) {
    justify-content: center;
  }
`;

const FooterStoryLink = styled(Link)<{ isStyleInverted: boolean }>`
  padding-top: 2%;
  color: ${(props) => (props.isStyleInverted ? styleVars.colors.blue : '#fff')};

  @media (max-width: ${styleVars.media.tablet}) {
    display: none;
  }

  &:hover {
    filter: brightness(120%);
  }
`;

const FooterAboutLink = styled(Link)<{ isStyleInverted: boolean }>`
  font-family: Whitney-Semibold;
  padding-top: 20%;
  color: ${(props) => (props.isStyleInverted ? styleVars.colors.blue : '#fff')};

  @media (max-width: ${styleVars.media.tablet}) {
    padding-top: 10%;
  }

  &:hover {
    filter: brightness(120%);
  }
`;

const SocialMediaWrapper = styled.div`
  padding: 3% 5% 3% 3%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex: 0 1 30%;

  @media (max-width: ${styleVars.media.tablet}) {
    align-items: center;
    justify-content: center;
    flex: 2;
    padding-bottom: 5%;
  }

  @media (max-width: ${styleVars.media.phone}) {
    padding: 0;
  }
`;

const SocialMediaButtons = styled.div`
  display: flex;
  gap: 10px;
  padding: 3% 0;

  @media (max-width: ${styleVars.media.tablet}) {
    gap: 5px;
    padding: 5% 0;
  }
`;

const SocialMediaItem = styled.div`
  @media (max-width: ${styleVars.media.tablet}) {
    font-size: ${styleVars.fontSizes.smaller};
    max-width: 300px;
  }
  @media (max-width: ${styleVars.media.phone}) {
    font-size: ${styleVars.fontSizes.small};
  }
`;

const CenterLogo = styled.div`
  max-width: 200px;

  @media (max-width: ${styleVars.media.tablet}) {
    width: 180px;
  }
`;

const SocialMediaResponsiveFlexContainer = styled.div`
  @media (max-width: ${styleVars.media.tablet}) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const SocialMediaLink = styled.div`
  padding-top: 2%;

  a {
    width: 100%;
    height: 100%;
  }

  .small-img {
    width: 35px;
    height: 35px;
  }

  &:hover {
    filter: brightness(120%);
  }

  @media (max-width: ${styleVars.media.tablet}) {
    .small-img {
      width: 25px;
      height: 25px;
    }
  }

  @media (max-width: ${styleVars.media.phone}) {
    .small-img {
      width: 30px;
      height: 30px;
    }
  }
`;

const Footer: React.FC = () => {
  const location = useLocation();

  const isAboutPage = !!matchPath(location.pathname, {
    path: '/2019/about'
  });

  return (
    <FooterDiv isStyleInverted={isAboutPage}>
      <FooterContent>
        <FooterContentStories>
          <FooterStoryHeader isStyleInverted={isAboutPage}>
            {content.impactTitle}
          </FooterStoryHeader>
          {content.impactLinks.map((link: any) => (
            <FooterStoryLink isStyleInverted={isAboutPage} to={link.url}>
              {link.title}
            </FooterStoryLink>
          ))}
        </FooterContentStories>
        <FooterContentLinks>
          {content.aboutLinks.map((link: any) => (
            <FooterAboutLink isStyleInverted={isAboutPage} to={link.url}>
              {link.title}
            </FooterAboutLink>
          ))}
        </FooterContentLinks>
      </FooterContent>
      <SocialMediaWrapper>
        <SocialMediaResponsiveFlexContainer>
          <CenterLogo>
            <img src={isAboutPage ? LogoBlue : Logo} alt="Center Logo" />
          </CenterLogo>
          <SocialMediaButtons>
            <SocialMediaLink>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fannualreport.cnycn.org/2018%2F&amp;src=sdkpreparse"
              >
                <img
                  className="small-img"
                  src={isAboutPage ? FacebookBlue : Facebook}
                  alt="Facebook"
                />
              </a>
            </SocialMediaLink>
            <SocialMediaLink>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.twitter.com/intent/tweet?text=Last%20year%2C%20we%20funded%20more%20than%20%2483M%20in%20zero-interest%20loans%20to%20keep%20homeowners%20in%20their%20homes.%20Check%20out%20%40CNYCN%E2%80%99s%20annual%20report%21%20&hashtags=homeownershipnyc&url=https://annualreport.cnycn.org/2018"
              >
                <img
                  className="small-img"
                  src={isAboutPage ? TwitterBlue : Twitter}
                  alt="Facebook"
                />
              </a>
            </SocialMediaLink>
            <SocialMediaLink>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/nyneighborhoods/"
              >
                <img
                  className="small-img"
                  src={isAboutPage ? InstagramBlue : Instagram}
                  alt="Instagram"
                />
              </a>
            </SocialMediaLink>
          </SocialMediaButtons>
          <SocialMediaItem>{content.contact.info}</SocialMediaItem>
          <SocialMediaItem>{content.contact.phone}</SocialMediaItem>
          <SocialMediaItem>{content.contact.email}</SocialMediaItem>
        </SocialMediaResponsiveFlexContainer>
      </SocialMediaWrapper>
    </FooterDiv>
  );
};

export default Footer;
