import christieImg from '../images/headshots/christie.jpg';
import howardImg from '../images/headshots/howie.jpg';
import natashaImg from '../images/headshots/natasha.jpg';
import lusheenaImg from '../images/headshots/lusheena.jpg';
import jessicaImg from '../images/headshots/jess.jpg';
import lucyImg from '../images/headshots/lucy.jpg';
import lisaImg from '../images/headshots/lisa.jpg';
import josephImg from '../images/headshots/joseph.jpg';
import cristianImg from '../images/headshots/cristian.jpg';
import carolineImg from '../images/headshots/caroline.jpg';
import bradenImg from '../images/headshots/braden.jpg';
import nicoleImg from '../images/headshots/nicole.jpg';
import zarImg from '../images/headshots/zar.jpg';
import chrisImg from '../images/headshots/chris.jpg';

interface BoroughContent {
  name: string;
  partnerNames: Array<string>;
}

interface BoardContent {
  title: string;
  names: Array<string>;
}

interface LeadershipContent {
  image: string;
  title: string;
  name: string;
}

interface FinancialsContent {
  title: string;
}

interface StaffContent {
  title: string;
  subTitleBoard: string;
  subTitleLeadership: string;
  board: Array<BoardContent>;
  leadership: Array<LeadershipContent>;
}

interface PartnersContent {
  title: string;
  partnerHeader: string;
  boroughHeader: string;
  sort: string;
  alpha: string;
  borough: string;
  boroughs: Array<BoroughContent>;
}

interface FundersContent {
  title: string;
  subHeader: string;
  funderList: Array<string>;
}

interface Keys {
  financials: FinancialsContent;
  staff: StaffContent;
  partners: PartnersContent;
  funders: FundersContent;
  [key: string]: any;
}

const content: Keys = {
  financials: {
    title: 'OUR 2019 FINANCIALS'
  },
  staff: {
    title: 'OUR STAFF',
    subTitleBoard: 'BOARD',
    subTitleLeadership: 'LEADERSHIP',
    board: [
      {
        title: 'Board Chair:',
        names: ['Herbert Sturz']
      },
      {
        title: 'Ex Officio:',
        names: ['Donovan Richards', 'Louise Carroll']
      },
      {
        title: 'Members:',
        names: [
          'Eric Enderlin',
          'Colvin Grannum',
          'Judi Kende',
          'Cathleen Mahon',
          'Sam Marks',
          'Ronay Menschel',
          'Jonathan Mintz',
          'Greg Schiefelbein',
          'Alexa Sewell',
          'Hakim Thompson',
          'Mathew Wambua',
          'Joseph Weisbord',
          'Kenneth Zimmerman'
        ]
      },
      {
        title: 'CEO/Executive Director:',
        names: ['Christie Peale']
      }
    ],
    leadership: [
      {
        image: christieImg,
        title: 'CEO/Executive Director',
        name: 'Christie Peale'
      },
      {
        image: natashaImg,
        title: 'Chief Financial Officer',
        name: 'Natasha Pallan'
      },
      {
        image: howardImg,
        title: 'Chief Lending Officer',
        name: 'Howard Banker'
      },
      {
        image: lucyImg,
        title: 'Vice President of Homeowner Services',
        name: 'Lucy Raimes'
      },
      {
        image: jessicaImg,
        title: 'Vice President of Development and External Affairs',
        name: 'Jessica Wells-Hasan'
      },
      {
        image: lusheenaImg,
        title: 'Chief of Staff',
        name: 'Lusheena Warner'
      },
      {
        image: lisaImg,
        title: 'General Counsel',
        name: 'Lisa Lofdahl'
      },
      {
        image: josephImg,
        title: ' Deputy General Counsel',
        name: 'Joseph Sant'
      },
      {
        image: cristianImg,
        title: 'Deputy Director, Policy and Communications',
        name: 'Cristian Salazar'
      },
      {
        image: carolineImg,
        title: 'Deputy Director, Policy and Research',
        name: 'Caroline Nagy'
      },
      {
        image: bradenImg,
        title: 'Deputy Director, Programs',
        name: 'Braden Listmann'
      },
      {
        image: nicoleImg,
        title: 'Deputy Director, Homeowner Experience',
        name: 'Nicole Zajdman'
      },
      {
        image: zarImg,
        title: 'Deputy Director, Lending',
        name: 'Zar Tun'
      },
      {
        image: chrisImg,
        title: 'Deputy Director, Systems and Vendor Management',
        name: 'Chris Sevigny'
      }
    ]
  },
  partners: {
    title: 'OUR PARTNERS',
    partnerHeader: 'Partner Organization',
    boroughHeader: 'Borough(s) Served',
    boroughs: [
      {
        name: 'Bronx',
        partnerNames: [
          'Bronx Legal Services',
          'Bronx Neighborhood Housing Services'
        ]
      },
      {
        name: 'Brooklyn',
        partnerNames: [
          'Brooklyn Legal Services Corporation A',
          'Bridge Street Development Corporation',
          'Brooklyn Legal Services',
          'Brooklyn Neighborhood Services CDC, Inc',
          'Brooklyn Bar Association Volunteer Lawyers Project',
          'CAMBA Legal, Inc.',
          'Community Organization of Southern Brooklyn',
          'Cypress Hills Local Development Corporation',
          'IMPACCT (Formerly Pratt Area Community Council)',
          'Neighbors Helping Neighbors, Inc',
          'Neighborhood Housing Services of Brooklyn CDC'
        ]
      },
      {
        name: 'Queens',
        partnerNames: [
          'Chhaya Community Development Corporation',
          'Jewish Association for Services for the Aged',
          'Margert Community Corporation',
          'Neighborhood Housing Services of Queens CDC',
          'Neighborhood Housing Services of Jamaica CDC',
          'Queens Legal Services',
          'Queens Volunteer Lawyer Project'
        ]
      },
      {
        name: 'Staten Island',
        partnerNames: [
          'Neighborhood Housing Services of Staten Island CDC',
          'Northfield Local Development Corporation',
          'Staten Island Legal Services'
        ]
      },
      {
        name: 'Citywide',
        partnerNames: [
          'Asian Americans for Equality',
          'Association of the Bar of the City of New York Fund',
          'Legal Aid Society',
          'Mobilization for Justice, Inc',
          'Parodneck'
        ]
      },
      {
        name: 'Bronx, Brooklyn',
        partnerNames: ['Housing & Family Services of Greater New York']
      },
      {
        name: 'Brooklyn, Queens',
        partnerNames: [
          'Grow Brooklyn, Inc',
          'Greater Sheepshead Bay Development Corporation',
          'Mutual Housing Association of New York Management, Inc'
        ]
      },
      {
        name: 'Bronx, Brooklyn, Manhattan, Queens',
        partnerNames: [
          'Neighborhood Housing Services of NYC CDC',
          'New York Legal Assistance Group'
        ]
      }
    ],
    sort: 'Sort By',
    alpha: 'A-Z',
    borough: 'Borough'
  },
  funders: {
    title: 'OUR FUNDERS',
    subHeader:
      'The Center is grateful to our funders for helping us reach and serve vulnerable New Yorkers every day. Their commitment to the Center and our work will have a lasting impact on New York’s residents and communities.',
    funderList: [
      'Altman Foundation',
      'Banco Santander',
      'Bank of America',
      'BankUnited',
      'BNB Bank',
      'Burchman Terrio Quist, LLC',
      'Caliber Home Loans',
      'Capital One Foundation',
      'Charina Foundation',
      'Citi Community Progress Makers',
      'Citi Community Development',
      'Community Preservation Corporation',
      'Community Restoration Fund',
      'Deutsche Bank',
      'Enterprise Community Partners, Inc.',
      'Fannie Mae',
      'Federal Home Loan Bank of NY',
      'FJC Foundation of Philanthropic Funds',
      'Ford Foundation',
      'Freddie Mac',
      'Goldman Sachs',
      `Governor's Office of Storm Recovery`,
      'Habitat for Humanity NYC',
      'HSBC Bank USA',
      'The JPM Chase & Co. Foundation',
      'Local Initiatives Support Corporation (LISC)',
      'M&T Bank',
      'Morgan Stanley',
      'Mr. Cooper Home Loans',
      'NeighborWorks America',
      'New York City Council',
      'New York Community Trust',
      'New York State CDFI',
      'New York State Energy Research & Development (NYSERDA)',
      'New York State Office of the Attorney General',
      'Nixon Peabody, LLP',
      'NYC Department of Finance',
      'NYC Department of Housing Preservation & Development',
      'NYC Housing Recovery Office',
      'Ocwen Financial Corporation',
      'Parks Municipal Consulting',
      'Seth Sprague Educational and Charitable Foundation',
      'SONYMA',
      'U.S. Department of Housing and Urban Development (HUD)',
      'Wells Fargo'
    ]
  }
};

export default content;
