interface Keys {
  [key: string]: string
}

const content: Keys = {
  title: '2018 Annual Report',
  impact: 'Stories',
  financials: 'Financials',
  staff: 'Staff',
  share: 'Share:'
};

export default content;
