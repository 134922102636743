import React from 'react';
import styled from 'styled-components';
import styleVars from './styles/variables';

const BoxStyling = styled.div`
  padding: 5%;
  margin-top: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  color: ${styleVars.colors.blue};
  background-color: ${styleVars.colors.lightBlue};
  font-size: 2rem;
  font-family: WhitneyCond-Semibold;

  @media (max-width: ${styleVars.media.desktop}) {
    margin-right: 5%;
    margin-left: 5%;
    font-size: 3rem;
  }

  @media (max-width: ${styleVars.media.tablet}) {
    margin-right: 0;
    margin-left: 0;
    font-size: ${styleVars.fontSizes.med};
  }
`;

const BoxCallOut: React.FC<{ content: string }> = ({ content }) => (
  <BoxStyling>{content}</BoxStyling>
);

export default BoxCallOut;
