import React from 'react';
import styled from 'styled-components';
import styleVars from './styles/variables';

const BoxStyling = styled.div`
  padding: 5%;
  margin-top: 5%;
  margin-left: 15%;
  margin-right: 15%;
  color: white;
  background-color: ${styleVars.colors.brandOrange};
  font-size: 2.5rem;
  font-family: WhitneyCond-Semibold;

  @media (max-width: ${styleVars.media.desktop}) {
    margin-right: 5%;
    margin-left: 5%;
    font-size: 3rem;
  }
`;

const BoxCallOut: React.FC<{content: string}> = ({ content }) => (
  <BoxStyling>
    {content}
  </BoxStyling>
);

export default BoxCallOut;
