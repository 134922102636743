import ethnicitySVG from '../images/pie-chart-race.svg';
import boroughSVG from '../images/pie-chart-borough.svg';
import styleVars from '../styles/variables';

interface Datapoint {
  stat: string;
  description: string;
}

interface GraphDatapoint {
  color: string;
  percentage: string;
  text: string;
}

interface Graph {
  graph: string;
  title: string;
  stats: Array<GraphDatapoint>;
}

interface Keys {
  title: string;
  datapoints: Array<Datapoint>;
  next: string;
  graphs: Array<Graph>;
}

const datapoints = [
  {
    stat: '$43,539',
    description: 'Average household income of families served'
  },
  {
    stat: '3,570',
    description: 'Connections made to high-quality free services'
  },
  {
    stat: '9',
    description: 'Number of languages spoken by Homeowner Hub'
  }
];

const ethnicityGraph = {
  graph: ethnicitySVG,
  title: 'RACE AND ETHNICITY',
  stats: [
    {
      color: styleVars.colors.brandBlue,
      percentage: '3%',
      text: 'Asian'
    },
    {
      color: styleVars.colors.brandOrange,
      percentage: '43%',
      text: 'Black'
    },
    {
      color: styleVars.colors.brandTeal,
      percentage: '0.27%',
      text: 'Native American'
    },
    {
      color: styleVars.colors.gray,
      percentage: '0.18%',
      text: 'Hawaiian/Pacific Islander'
    },
    {
      color: styleVars.colors.brandYellow,
      percentage: '10%',
      text: 'Hispanic'
    },
    {
      color: styleVars.colors.brandGreen,
      percentage: '19%',
      text: 'White'
    },
    {
      color: styleVars.colors.brandPurple,
      percentage: '25%',
      text: 'Other/Multiracial'
    }
  ]
};

const boroughGraph = {
  graph: boroughSVG,
  title: 'SERVED BY BOROUGH',
  stats: [
    {
      color: styleVars.colors.brandOrange,
      percentage: '48.63%',
      text: 'Brooklyn'
    },
    {
      color: styleVars.colors.brandYellow,
      percentage: '29.77%',
      text: 'Queens'
    },
    {
      color: styleVars.colors.brandPurple,
      percentage: '8.10%',
      text: 'Staten Island'
    },
    {
      color: styleVars.colors.brandBlue,
      percentage: '2.57%',
      text: 'Manhattan'
    },
    {
      color: styleVars.colors.brandGreen,
      percentage: '10.93%',
      text: 'Bronx'
    }
  ]
};

const content: Keys = {
  title: 'Homeowners Served',
  datapoints,
  next: 'Read Highlights',
  graphs: [ethnicityGraph, boroughGraph]
};

export default content;
