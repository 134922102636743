import React, { useState, useContext } from 'react';
import styled, { keyframes } from 'styled-components';
import axios from 'axios';
import CloseButton from './images/exit.svg';
import content from './content/modal';
import ModalContext from './context';
import styleVars from './styles/variables';

const Modal = styled.div<{active: boolean}>`
  display: ${(props) => (props.active ? 'block' : 'none')};
  position: fixed;
  z-index: 3;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
`;

const ModalPopup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  color: black;
  position: fixed;
  z-index: 3;
  left: 25%;
  top: 10%;
  width: 50%;
  overflow-y: auto;
  border: solid black 1px;

  img {
    position: absolute;
    top: 2%;
    right: 5%;
    width: 10%;
    height: 10%;

    &:hover {
      cursor: pointer;
    }

    @media (max-width: ${styleVars.media.tablet}) {
      top: 5%;
    }
  }

  @media (max-width: ${styleVars.media.desktop}) {
    left: 10%;
    width: 80%;
  }
`;

const ModalHeader = styled.div`
  font-family: WhitneyCond-Semibold;
  font-size: ${styleVars.fontSizes.medSmall};
  padding: 10% 10% 0 10%;
  text-align: center;

  @media (min-width: ${styleVars.media.desktop}) {
    padding-top: 5%;
  }
`;

const ModalDescription = styled.div`
  font-family: Whitney-Book;
  font-size: ${styleVars.fontSizes.smaller};
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 80%;
  padding: 1% 10%;

  a {
    text-decoration: underline;
    color: black;
  }
`;

const ModalContent = styled.div`
  font-family: Whitney-Book;
  font-size: ${styleVars.fontSizes.smaller};
  display: flex;
  flex-direction: column;
  width: 80%;
  padding: 1% 10%;
`;

const rotate = keyframes`
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
`;

const ModalSpinner = styled.div`
  height: 25vh;
  position: relative;
  transition: opacity linear 0.1s;
  text-align: center;
  margin: 2em;

  &::before {
    animation: ${rotate} 2s linear infinite;
    border: solid 3px #eee;
    border-bottom-color: ${styleVars.colors.brandTeal};
    border-radius: 50%;
    content: "";
    height: 40px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    transform-origin: center;
    width: 40px;
    will-change: transform;
  }
`;

const ModalSubmit = styled.button`
  font-family: Whitney-Book;
  background-color: ${styleVars.colors.brandTeal};
  margin-bottom: 10%;

  @media (max-width: ${styleVars.media.tablet}) {
    height: 35px;
  }
`;

interface IForm {
  name: string,
  email: string,
  phone: string,
  subject: string,
  message: string
}

const ContactModal: React.FC = () => {
  const { modalActive, setModalActive } = useContext(ModalContext);

  const [formData, setFormData] = useState<IForm>({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const sendData = () => {
    setLoading(true);
    axios({
      url: '/api/v1/contact',
      method: 'post',
      data: {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        subject: formData.subject,
        message: formData.message
      }
    }).then(() => {
      setLoading(false);
      setSuccess(true);
    }).catch(() => {
      setLoading(false);
      setError(true);
    });
  };

  const handleChange = (event: any) => {
    const key = event.target.name;
    setFormData((prevState) => ({
      ...prevState,
      [key]: event.target.value
    }));
    event.persist();
  };

  const renderContent = () => {
    if (loading) {
      return (
        <>
          <ModalHeader>
            {content.loadingHeader}
          </ModalHeader>
          <ModalSpinner />
        </>
      );
    }

    if (error) {
      return (
        <>
          <ModalHeader>
            {content.errorHeader}
          </ModalHeader>
          <ModalDescription dangerouslySetInnerHTML={{ __html: content.errorDescription }} />
        </>
      );
    }

    if (success) {
      return (
        <>
          <ModalHeader>
            {content.successHeader}
          </ModalHeader>
          <ModalDescription>
            {content.sucessDescription}
          </ModalDescription>
        </>
      );
    }

    return (
      <>
        <ModalHeader>
          {content.header}
        </ModalHeader>
        <ModalDescription>
          {content.description}
        </ModalDescription>
        <ModalContent>
          {content.name}
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </ModalContent>
        <ModalContent>
          {content.email}
          <input
            type="email"
            name="email"
            placeholder="e.g. example@gmail.com"
            value={formData.email}
            onChange={handleChange}
          />
        </ModalContent>
        <ModalContent>
          {content.phone}
          <input
            type="tel"
            name="phone"
            placeholder="e.g. 123-456-7890"
            value={formData.phone}
            onChange={handleChange}
          />
        </ModalContent>
        <ModalContent>
          {content.subject}
          <select
            name="subject"
            id="subject"
            value={formData.subject}
            onChange={handleChange}
          >
            <option value="">{content.subjectOptionDefault}</option>
            <option value="contact">{content.subjectOptionContact}</option>
            <option value="donate">{content.subjectOptionDonate}</option>
            <option value="service">{content.subjectOptionService}</option>
            <optgroup />
          </select>
        </ModalContent>
        <ModalContent>
          {content.message}
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
          />
        </ModalContent>
        <ModalSubmit
          onClick={sendData}
        >
          {content.submit}
        </ModalSubmit>
      </>
    );
  };

  return (
    <Modal active={modalActive}>
      <ModalPopup>
        <img
          onClick={() => setModalActive(false)}
          src={CloseButton}
          alt="Close Button"
        />
        {renderContent()}
      </ModalPopup>
    </Modal>
  );
};

export default ContactModal;
