import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{backgroundUrl: string, backgroundPosition?: string}>`
  min-height: 100vh;
  width: 100%;
  position: relative;
  background-image: url(${(props) => props.backgroundUrl});
  background-size: cover;
  background-position: ${(props) => props.backgroundPosition || 'center'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

interface Props {
  id?: string,
  backgroundUrl: string,
  backgroundPosition?: string
}

const WindowSizedWrapper: React.FC<Props> = ({
  id, backgroundUrl, backgroundPosition, children
}) => (
  <Wrapper
    id={id}
    backgroundUrl={backgroundUrl}
    backgroundPosition={backgroundPosition}
  >
    {children}
  </Wrapper>
);

export default WindowSizedWrapper;
