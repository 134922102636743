import React from 'react';
import styled from 'styled-components';
import WindowSizedWrapper from './WindowSizedWrapper';
import content from './content/noMatch';
import background from './images/home-teal-grid-1.svg';
import styleVars from './styles/variables';

const ContentWrapper = styled.div`
  height: 100%;
  color: white;
`;

const Header = styled.h3`
  margin: 0 13%;

  @media (max-width: ${styleVars.media.tablet}) {
    margin: 0 1%;
  }
`;

const NoMatch: React.FC = () => (
  <WindowSizedWrapper backgroundUrl={background}>
    <ContentWrapper>
      <Header>
        <p>{content.header}</p>
      </Header>
      <h4>{content.subheader}</h4>
    </ContentWrapper>
  </WindowSizedWrapper>
);

export default NoMatch;
