import React from 'react';
import styled from 'styled-components';
import WindowSizedWrapper from './WindowSizedWrapper';
import NextSection from './NextSection';
import content from './content/landing';
import logo from './images/cny-logo-nav.svg';
import background from './images/red-hook-homes.jpeg';
import styleVars from './styles/variables';

const CenterContent = styled.div`
  min-height: 100%;
  color: white;

  img {
    height: 5.625em;
  }
`;

const Header = styled.h1`
  margin: 2rem auto;
  padding: 1rem;
  width: fit-content;
  background-color: ${styleVars.colors.blue};
`;

const Landing: React.FC = () => (
  <WindowSizedWrapper backgroundUrl={background} backgroundPosition="right">
    <CenterContent>
      <img src={logo} alt="CNYCN" />
      <Header>{content.header}</Header>
    </CenterContent>
    <NextSection text={content.next} nextId="impact" />
  </WindowSizedWrapper>
);

export default Landing;
