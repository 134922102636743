import dollar from '../images/dollar-green.svg';
import house from '../images/house-green.svg';

interface Datapoint {
  stat: string,
  description: string,
  icon: {
    src: string,
    alt: string
  }
}

interface Keys {
  title: string,
  datapoints: Array<Datapoint>,
  next: string,
  image: {
    src: string,
    alt: string
  }
}

const datapoints = [
  {
    stat: '$133M+',
    description: 'total savings for homeowners',
    icon: {
      src: dollar,
      alt: 'dollar sign'
    }
  },
  {
    stat: '18K+',
    description: 'homes saved',
    icon: {
      src: house,
      alt: 'house icon'
    }
  },
  {
    stat: '$10B+',
    description: 'of property preserved',
    icon: {
      src: dollar,
      alt: 'dollar sign'
    }
  }
];

const content: Keys = {
  title: 'From 2008 to the Present',
  datapoints,
  next: 'Homeowners Served',
  // TODO: get real image
  image: {
    src: 'https://picsum.photos/565/480',
    alt: 'Picture of family'
  }
};

export default content;
