import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import styleVars from './styles/variables';
import content from './content/footer';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${styleVars.colors.lightGray}
  text-align: center;
  padding: 2%;
  color: ${styleVars.colors.gray};

  > a {
    border-right: 1px solid ${styleVars.colors.darkGray};

    :last-child {
      border: none;
    }
  }
`;

const ExternalLink = styled.a`
  color: ${styleVars.colors.darkGray};
  padding: 0 5%;
  &:hover {
    filter: brightness(120%);
  }
`;

const InternalLink = styled(Link)`
  color: ${styleVars.colors.darkGray};
  padding: 0 5%;

  &:hover {
    filter: brightness(120%);
  }
`;

const GlobalFooter: React.FC = () => {
  const location = useLocation();
  const isSelectPage = location.pathname.match(/^\/$/);

  return (
    <Wrapper>
      <ExternalLink target="_blank" href="https://www.cnycn.org/privacy">
        {content.privacy}
      </ExternalLink>
      <InternalLink to="/terms-and-conditions">{content.terms}</InternalLink>
      {!isSelectPage && <InternalLink to="/">{content.select}</InternalLink>}
    </Wrapper>
  );
};

export default GlobalFooter;
