import React, { useState } from 'react';
import styled from 'styled-components';
import styleVars from './styles/variables';
import content from './content/about';
import AboutTabBar from './AboutTabBar';

interface PartnerInfo {
  name: string;
  served: string;
}

const Wrapper = styled.div`
  background-color: ${styleVars.colors.blue};
  color: #fff;
`;

const Header = styled.h2`
  padding-top: 5%;
  margin: 0 5%;
  border-bottom: 1px solid white;
  font-family: WhitneyCond-Light;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem;

  @media (max-width: ${styleVars.media.phone}) {
    flex-direction: column;
  }
`;

const OuterListWrapper = styled.div`
  display: flex;
  font-family: WhitneyCond-Book;
  justify-content: center;
  font-size: ${styleVars.fontSizes.smallest};
  gap: 250px;

  @media (max-width: ${styleVars.media.tablet}) {
    gap: 25px;
  }
`;

const ListInnerFlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  @media (max-width: 850px) {
    font-size: ${styleVars.fontSizes.smallest};
  }
`;

const PartnerItem = styled.div`
  flex: 0 1 40px;
  width: 100%;

  @media (max-width: ${styleVars.media.tablet}) {
    flex-basis: 50px;
    font-size: ${styleVars.fontSizes.smallest};
  }
  @media (max-width: ${styleVars.media.phone}) {
    flex-basis: 70px;
  }
  @media (max-width: 300px) {
    flex-basis: 120px;
  }
`;

const ColumnHeader = styled.div<{ partner?: boolean }>`
  font-family: WhitneyCond-Semibold;
  width: 100%;
  margin-bottom: 10px;

  @media (max-width: ${styleVars.media.tablet}) {
    font-size: ${styleVars.fontSizes.smallest};
  }
  @media (max-width: 346px) {
    flex-basis: ${(props) => props.partner && '36px'};
  }
`;

const AboutPartners: React.FC = () => {
  const sortArray = (firstPartner: PartnerInfo, secondPartner: PartnerInfo) => {
    return firstPartner.name.localeCompare(secondPartner.name);
  };

  const buildSortedPartners = () => {
    const allPartners: Array<PartnerInfo> = [];

    content.partners.boroughs.forEach((borough) => {
      borough.partnerNames.forEach((partner) => {
        const partnerObject = {
          name: partner,
          served: borough.name
        };
        allPartners.push(partnerObject);
      });
    });

    return allPartners.sort(sortArray);
  };

  const renderPartners = () => {
    const sortedPartners = buildSortedPartners();

    const partners = sortedPartners.map((partner) => {
      return <PartnerItem>{partner.name}</PartnerItem>;
    });

    const boroughs = sortedPartners.map((partner) => {
      return <PartnerItem>{partner.served}</PartnerItem>;
    });

    return (
      <OuterListWrapper>
        <ListInnerFlexWrapper>
          <ColumnHeader partner>{content.partners.partnerHeader}</ColumnHeader>
          {partners}
        </ListInnerFlexWrapper>
        <ListInnerFlexWrapper>
          <ColumnHeader>{content.partners.boroughHeader}</ColumnHeader>
          {boroughs}
        </ListInnerFlexWrapper>
      </OuterListWrapper>
    );
  };

  return (
    <>
      <AboutTabBar />
      <Wrapper>
        <Header>{content.partners.title}</Header>
        <BodyWrapper>{renderPartners()}</BodyWrapper>
      </Wrapper>
    </>
  );
};

export default AboutPartners;
