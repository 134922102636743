import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import styleVars from './styles/variables';
import content from './content/navBar';
import TwitterWhite from './images/social-icon-twitter.svg';
import FacebookWhite from './images/social-icon-facebook.svg';

const NavImg = styled.img`
  padding: 10px;
  height: 30px;
  max-width: 150px;

  @media (max-width: 600px) {
    height: 50px;
    padding: 5px;
  }

  &:hover {
    filter: brightness(120%);
  }
`;

const NavItems = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 1rem;

  @media (max-width: 600px) {
    flex: 1 1 auto;
    display: flex;
    font-size: ${styleVars.fontSizes.med};
    flex-direction: column;
    flex: initial;
    align-items: flex-end;
    justify-content: center;
    padding: 60px 0px 10px;
    margin-right: 0;
  }
`;

const NavItem = styled.div`
  display: flex;
  color: ${styleVars.colors.blue};
  height: 50px;
  justify-content: center;
  margin-right: 2rem;

  @media (max-width: ${styleVars.media.tablet}) {
    p {
      font-size: ${styleVars.fontSizes.smaller};
    }
  }

  @media (max-width: 600px) {
    flex: 1 1 auto;
    width: 100%;
    margin-right: 0;

    p {
      display: none;
    }
  }
`;

const NavItemMultiple = styled.div`
  color: ${styleVars.colors.blue};
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${styleVars.media.tablet}) {
    p {
      display: none;
    }
  }

  @media (max-width: 600px) {
    flex: 5 1 auto;
    padding-top: 10%;
    width: 100%;
  }
`;

const NavLink = styled(Link)`
  font-family: Whitney-Semibold;
  font-size: ${styleVars.fontSizes.smallest};
  color: ${styleVars.colors.blue};
  display: flex;
  align-items: center;
  height: 100%;

  &:hover {
    filter: brightness(120%);
  }

  @media (max-width: 600px) {
    font-size: ${styleVars.fontSizes.smaller};
  }
`;

const NavLinks: React.FC = () => (
  <NavItems>
    <NavItem>
      <NavLink to="/2019/highlights/change-and-advocacy">
        {content.impact}
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink to="/2019/about/financials">{content.financials}</NavLink>
    </NavItem>
    <NavItem>
      <NavLink to="/2019/about/staff">{content.staff}</NavLink>
    </NavItem>
    <NavItemMultiple>
      <p>{content.share}</p>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fannualreport.cnycn.org%2F&amp;src=sdkpreparse"
      >
        <NavImg src={FacebookWhite} alt="Facebook Logo" />
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://twitter.com/intent/tweet?text=Last%20year%2C%20we%20funded%20more%20than%20%2483M%20in%20zero-interest%20loans%20to%20keep%20homeowners%20in%20their%20homes.%20Check%20out%20%40CNYCN%E2%80%99s%20annual%20report%21%20&hashtags=homeownershipnyc&url=https://annualreport.cnycn.org"
      >
        <NavImg src={TwitterWhite} alt="Twitter Logo" />
      </a>
    </NavItemMultiple>
  </NavItems>
);

export default NavLinks;
