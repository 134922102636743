interface Datapoint {
  stat: string,
  description: string
}

interface Keys {
  title: string,
  datapoints: Array<Datapoint>,
  next: string,
  image: {
    alt: string
  }
}

const datapoints = [
  {
    stat: '28,819',
    description: 'people served'
  },
  {
    stat: '$83M',
    description: 'in zero interest loans to nearly 2,800 New Yorkers'
  },
  {
    stat: '355,033',
    description: 'people reached'
  },
  {
    stat: '256',
    description: 'homes strengthened'
  },
  {
    stat: '10,658',
    description: 'engagements by our phone number, operated by the Homeowner Hub'
  },
  {
    stat: '$325,850',
    description: 'invested in resiliency for coastal communities'
  }
];

const content: Keys = {
  title: 'In 2018',
  datapoints,
  next: 'Since 2008',
  image: {
    alt: 'Picture of family'
  }
};

export default content;
