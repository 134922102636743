import React from 'react';
import styled from 'styled-components';
import styleVars from './styles/variables';
import TabBar from './TabBar';
import BoxCallOut from './BoxCallOut';
import Paragraph from './Paragraph';
import ContactButton from './ContactButton';
import HighlightStoryNav from './HighlightStoryNav';
import contactBtn from './images/contact-btn.svg';
import content, { Story } from './content/highlightStories';

const HighlightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding-bottom: 3rem;
  position: relative;
  align-items: center;
  margin-top: 1rem;
`;

const Title = styled.h2`
  z-index: 2;
  color: ${styleVars.colors.blue};
  padding-top: 4%;
  font-family: Whitney-Semibold;
  max-width: 45rem;
  margin: 0 3rem;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 60rem;
  margin: 3rem 3rem 0 3rem;

  @media (max-width: ${styleVars.media.tablet}) {
    max-width: 50rem;
  }
`;

const HeroImage = styled.img`
  z-index: 2;
  width: 100%;
  margin-bottom: 1rem;
`;

const SubHeading = styled.h4`
  font-family: Whitney-Semibold;
  margin: 2rem 0 1rem 10%;
  color: ${styleVars.colors.blue};
  @media (max-width: ${styleVars.media.tablet}) {
    margin-left: 0;
  }
`;

const ArticleLink = styled.a`
  background-color: ${styleVars.colors.blue};
  font-size: ${styleVars.fontSizes.smaller};
  font-family: Whitney-Semibold;
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  color: ${styleVars.colors.white};
  width: 770px;
  padding: 20px 0;
  margin: 2rem 0 0 10%;

  @media (max-width: ${styleVars.media.desktop}) {
    margin: 2.25rem 0 0 0;
    width: 100%;
  }

  @media (max-width: ${styleVars.media.tablet}) {
    font-size: ${styleVars.fontSizes.small};
  }

  @media (max-width: 320px) {
    font-size: ${styleVars.fontSizes.smaller};
  }

  &:hover {
    filter: brightness(120%);
  }
`;

const renderBoxCallOuts = (story: Story) => {
  if (story.callOut) {
    return story.callOut.map((call: string) => <BoxCallOut content={call} />);
  }

  return null;
};

const renderParagraphs = (story: Story) =>
  story.paragraphs.map((storyContent) => <Paragraph content={storyContent} />);

const HighlightStories: React.FC<{ story: string }> = ({ story }) => (
  <>
    <TabBar />
    <HighlightWrapper>
      <Title>{content[story].title}</Title>
      <ContentWrapper>
        <HeroImage src={content[story].heroImage} alt="Hero Image" />
        {renderParagraphs(content[story])}
        {content[story].subheading && (
          <SubHeading>{content[story].subheading}</SubHeading>
        )}
        {renderBoxCallOuts(content[story])}

        {content[story].link && (
          <ArticleLink
            target="_blank"
            href="https://www.nytimes.com/2019/10/21/nyregion/deed-theft-brooklyn.html"
          >
            {content[story].link}
          </ArticleLink>
        )}
        <HighlightStoryNav />
      </ContentWrapper>
      <ContactButton button={contactBtn} />
    </HighlightWrapper>
  </>
);

export default HighlightStories;
