import React from 'react';
import styled from 'styled-components';
import StatsHeader from './StatsHeader';
import Datapoint from './Datapoint';
import NextSection from './NextSection';
import content from './content/statsSince2008';
import styleVars from './styles/variables';
import image from './images/family-on-stoop-2.jpg';

const Wrapper = styled.div`
  background-color: ${styleVars.colors.lightBlue};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;

  * {
    box-sizing: border-box;
  }
`;

const StatsWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto repeat(3, minmax(150px, 300px));
  grid-gap: 2rem;
  justify-content: center;
  width: 90%;
  margin-bottom: 6rem;

  @media (max-width: 1350px) {
    width: 70%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  @media (max-width: 600px) {
    width: 90%;
  }
`;

const ImageWrapper = styled.div`
  grid-column: span 2;

  @media (max-width: 1350px) {
    grid-column: span 1;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StatsSince2008: React.FC = () => (
  <Wrapper id="stats-2008">
    <StatsHeader title={content.title} />
    <StatsWrapper>
      <ImageWrapper>
        <Image alt={content.image.alt} src={image} />
      </ImageWrapper>
      {content.datapoints.map((data) => (
        <Datapoint data={data} fontColor={styleVars.colors.blue} />
      ))}
    </StatsWrapper>
    <NextSection
      text={content.next}
      nextId="stats-homeowners"
      color="blue"
      relativePosition
    />
  </Wrapper>
);

export default StatsSince2008;
