import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import styleVars from './styles/variables';
import content from './content/navBar';
import Logo from './images/CNYCN-logo-blue.svg';
import MobileMenu from './MobileMenu';
import NavLinks from './NavLinks';

const NavWrapper = styled.nav<{ isHome: boolean }>`
  display: flex;
  position: ${(props) => (props.isHome ? 'fixed' : 'inherit')};
  z-index: ${(props) => (props.isHome ? '3' : 'inherit')};
  width: ${(props) => (props.isHome ? '100%' : 'inherit')};
  height: 50px;
  background-color: white;
  align-items: center;
`;

const NavImg = styled.img`
  padding: 0 1rem;
  height: 30px;

  &:hover {
    filter: brightness(120%);
  }
`;

const Title = styled.span`
  font-size: 1.25rem;

  @media (max-width: ${styleVars.media.tablet}) {
    font-size: ${styleVars.fontSizes.smaller};
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

const LinkWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;

  @media (max-width: 600px) {
    display: none;
  }
`;

const MobileLinkWrapper = styled.div`
  @media (max-width: 600px) {
    display: inherit;
  }
`;

const HomeWrapper = styled.div`
  flex: 5 1 auto;
  display: flex;
  color: ${styleVars.colors.blue};
  height: 50px;
  align-items: center;
`;

const NavBar: React.FC = () => {
  const location = useLocation();
  const isHome = !location.pathname.match(/\/highlights|\/about/g);

  return (
    <NavWrapper isHome={isHome}>
      <HomeWrapper>
        <Link onClick={() => window.scrollTo(0, 0)} to="/2019">
          <NavImg src={Logo} alt="Center Logo" />
        </Link>
        <Title>{content.title}</Title>
      </HomeWrapper>
      <LinkWrapper>
        <NavLinks />
      </LinkWrapper>
      <MobileLinkWrapper>
        <MobileMenu />
      </MobileLinkWrapper>
    </NavWrapper>
  );
};

export default NavBar;
