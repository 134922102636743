interface Datapoint {
  stat: string;
  description: string;
}

interface Keys {
  title: string;
  datapoints: Array<Datapoint>;
  next: string;
  image: {
    alt: string;
  };
}

const datapoints = [
  {
    stat: '20,344',
    description: 'people served'
  },
  {
    stat: '$18.7M',
    description: 'in zero interest loans to 420 New Yorkers'
  },
  {
    stat: '330,492',
    description: 'people reached'
  },
  {
    stat: '65',
    description: 'homes strengthened'
  },
  {
    stat: '7,198',
    description:
      'engagements by our phone number, operated by the Homeowner Hub'
  },
  {
    stat: '$258,274',
    description: 'invested in resiliency for coastal communities'
  }
];

const content: Keys = {
  title: 'In 2019',
  datapoints,
  next: 'Since 2008',
  image: {
    alt: 'Picture of family'
  }
};

export default content;
