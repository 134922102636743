import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import styleVars from './styles/variables';
import leftArrow from './images/blue-arrow-left.svg';
import rightArrow from './images/blue-arrow-right.svg';
import content from './content/tabBar';

const StoryNav = styled.div`
  position: relative;
  margin: 3rem 10%;

  @media (max-width: ${styleVars.media.desktop}) {
    margin: 3rem 0;
  }
`;

const StoryNavLink = styled(Link)`
  position: absolute;
  display: flex;
  align-items: center;
  font-family: WhitneyCond-Semibold;
  font-size: ${styleVars.fontSizes.smaller};
  color: ${styleVars.colors.blue};

  &:hover {
    filter: brightness(120%);
  }

  span {
    margin: 0 1rem;
  }
`;

const PreviousStory = styled(StoryNavLink)`
  left: 0;
`;

const NextStory = styled(StoryNavLink)`
  right: 0;
  color: ${styleVars.colors.blue};
`;

const HighlightStoryNav: React.FC = () => {
  const storyIndex = content.impact
    .map((item: any) => item.url)
    .indexOf(window.location.pathname);
  const isFirstStory = storyIndex === 0;
  const isLastStory = storyIndex === content.impact.length - 1;
  const previousStoryUrl = !isFirstStory
    ? content.impact[storyIndex - 1].url
    : '';
  const nextStoryUrl = !isLastStory ? content.impact[storyIndex + 1].url : '';

  return (
    <StoryNav>
      {!isFirstStory && (
        <PreviousStory to={previousStoryUrl}>
          <img src={leftArrow} alt="left arrow" />
          <span>Previous Story</span>
        </PreviousStory>
      )}
      {!isLastStory && (
        <NextStory to={nextStoryUrl}>
          <span>Next Story</span>
          <img src={rightArrow} alt="right arrow" />
        </NextStory>
      )}
    </StoryNav>
  );
};

export default HighlightStoryNav;
