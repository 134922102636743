import heroEnergy from '../images/impact_stories/climate-justice.jpg';
import heroSeniors from '../images/impact_stories/seniors.jpg';
import heroHelp from '../images/impact_stories/helping.jpg';
import heroHousing from '../images/impact_stories/housing.jpg';

interface Story {
  title: string,
  pullQuote: string,
  callOut: string,
  caption: string,
  paragraphOne: string,
  paragraphTwo: string,
  paragraphThree: string,
  heroImage: string
}

interface Stories {
  [key: string]: Story
}

const content: Stories = {
  storyOne: {
    title: 'Saving Homes Sustains Communities',
    heroImage: heroEnergy,
    pullQuote: '“Ultimately they saved my life, because they saved my home.”',
    callOut: 'From 2016 to 2019, the New York State Mortgage Assistance Program funded over 1,900 loans, leveraging $80 million to preserve over $1 billion in equity for New Yorkers.',
    caption: 'Learn more about how the Center’s New York State Mortgage Assistance Program was able to help Karol keep her home',
    paragraphOne: 'Hundreds of thousands of working- and middle-class families own homes in New York, and over a third struggle to afford their monthly housing costs. Karol Thwaites was one of these homeowners until she learned about our New York State Mortgage Assistance Program (NYS-MAP), which was a 0% interest mortgage loan program administered by the Center. Karol had fallen behind on her mortgage payments for her Harlem apartment when she turned to K. Scott Kohanowski, a lawyer at the City Bar Justice Center, one of our Network Partners, who connected her to the program. Our lending staff were able to help stabilize her finances with a NYS-MAP loan.',
    paragraphTwo: 'Initially created to assist homeowners who had fallen behind on mortgage payments and had exhausted every other option to keep from losing their homes, the state-wide program offered mortgage loans up to $80,000. The $80 million program funded over 1,900 loans from 2016 to 2019, helping approximately 3,000 families.',
    paragraphThree: 'Not only were homeowners able to keep their homes, but it also had a net effect of preserving over $1 billion in equity for their neighbors. Nevertheless, there are currently over 40,000 foreclosure cases pending across New York state – a clear sign that there is still work to be done and that many families are living precariously more than a decade after the financial crisis.'
  },
  storyTwo: {
    title: 'Homeowners Are Climate Justice Heroes',
    heroImage: heroEnergy,
    pullQuote: '“Everyone can do their part, I really believe that. That’s why I’m always looking for ways to be more sustainable.”',
    callOut: 'The Center is poised to affect change alongside individual homeowners who collectively, can make a big difference.',
    caption: 'Ursula in front of her now more-sustainable Brooklyn home',
    paragraphOne: 'As an organization focused on combating the effects of the climate crisis in our neighborhoods, the Center is poised to affect change alongside individual homeowners who, collectively, can make a big difference by reducing carbon emissions.',
    paragraphTwo: 'As lead New York City partner for the Community Energy Engagement Program (CEEP), funded by the New York State Energy Research and Development Authority, the Center sends highly trained staff out into communities across New York City to educate working- and middle-class families on the latest ways to save money on utility bills and decrease their energy use. Our Community Advisors connect residents to grants, rebates, and incentives, along with counselors in our network who can walk them through financial options for affording upgrades.',
    paragraphThree: 'Bed-Stuy resident Ursula Owens learned how to reduce her carbon footprint through the help of our team of community advisors. Owens, who is always looking to increase her brownstone’s energy efficiency, doesn’t think we’re doing a good enough job tackling the climate crisis. But she says it’s important to think about future generations. “I want to leave the planet to my kids,” she says. The Center’s CEEP team connected her to a contractor who installed smoke and C02 detectors, insulated her home, and even got her a new energy-efficient refrigerator for free. She’s now looking into installing solar panels on her roof. As someone who grew up hearing about solar panels thinking they were part of a futuristic world, she never thought something like that would be available to her in her lifetime. “Everyone can do their part, I really believe that,” she says. “That’s why I’m always looking for ways to be more sustainable.” Thanks to CEEP, Ursula and other homeowners like her can be heroes in the fight against the climate crisis, making their homes not only more affordable, but also more sustainable for their families, their communities, and the city.'
  },
  storyThree: {
    title: 'Counseling Makes The Difference In Disaster Recovery',
    heroImage: heroHousing,
    pullQuote: '“I feel like I had made new friends.”',
    callOut: '400,000 New Yorkers now live in FEMA’s proposed high-risk flood zone, where it is increasingly expensive for families to protect and maintain their homes.',
    caption: 'Peggy and Ray in front of their newly restored and raised Breezy Point home',
    paragraphOne: 'Breezy Point homeowners Peggy Craig and her husband Ray nearly lost everything when Superstorm Sandy hit eight years ago. They’ll never forget seeing the water rush into their streets like a rising river, or watching helplessly as neighboring homes caught fire from ash carried by hurricane-force winds. “It felt like one of those movies about the apocalypse!” Ray says. Recovering from a disaster like a hurricane can be complicated. The Center learned in the wake of Sandy that having a non-profit lawyer or housing advocate on your side as you navigate insurance companies, mortgage relief, and complex federal and local regulations, can make recovery a lot smoother, especially for households without significant financial resources. As families like the Craigs rebuilt after the storm, the Center, through its housing counseling program, helped over 5,000 homeowners navigate a complex disaster recovery process. ',
    paragraphTwo: 'While the City  restored and raised their home, the Craigs became temporarily homeless, and weren’t sure how to find an affordable place to live. Luckily for them, they weren’t left to fend for themselves. Through our Temporary Housing Services, in partnership with the New York Disaster Interfaith Services, we were able to provide thoughtful housing counseling, monthly visits, and safe temporary housing that was in their community and close to their grown children, as they waited for their home to be restored and made more resilient against future storms. “They were great, we had a great experience with them,” Ray says. “They showed us two apartments, and the first one was perfect. It was the best experience we could imagine.” These kind and dedicated relationships ended up making all the difference. Now when she mentions NYDIS, Peggy smiles. “I feel like we made new friends,” she says. ',
    paragraphThree: 'We’re proud of our work helping families from the beginning of reconstruction all the way through moving back into their homes, but so much work lies ahead. Currently, 400,000 New Yorkers live in FEMA’s proposed high-risk flood zone, making it increasingly expensive for families to protect and maintain their homes -- yet it is clearly necessary! As more homeowners learn that they live in high-risk flood zones (which any New Yorker can check here), and as the impacts of storms and floods worsen due to climate change, it’s clear that an increasing number of families like the Craigs would benefit from housing counseling being integrated into recovery programs. '
  },
  storyFour: {
    title: 'Fighting For Seniors To Age In Community',
    heroImage: heroSeniors,
    pullQuote: '“As a woman, it feels good to have something of my own.” ',
    callOut: 'There has been a 29% increase in the number of homeowners ages 65 and above between 2008 and 2017, many of whom are of low-to-moderate income.',
    caption: "Center for NYC Neighborhoods' Senior Resource Guide cover",
    paragraphOne: 'One of our main objectives at the Center is to help older homeowners to age in community. That’s why we created the Center’s Senior Homeowner Initiative. With over 120 community stakeholders, our citywide network of community-based nonprofit legal services and housing counseling organizations is dedicated to engaging, educating, and serving elderly homeowners through the initiative, funded by the New York City Council. Now in its fourth year, we work with mortgage lenders to collectively identify trends and connect with older homeowners; advance policies that protect senior homeowners; and provide services to address foreclosure, estate planning, and financial issues. So far, we have helped thousands of seniors across New York City and prevented over 2,300 foreclosures.',
    paragraphTwo: 'The program is more important than ever: The city’s senior population expected to increase over the next 15 years from 1.4 to 1.8 million, three-quarters of whom are low- to moderate-income. Meanwhile, many are living on fixed incomes and will need resources and services to support them as they age in community, and to protect them from financial shocks. Judith King, of St. Alban’s Queens, is one of those homeowners. When the 74-year-old contacted us, she was behind on her taxes and had taken out a reverse mortgage, a home financing option for homeowners 62 and older who have accumulated home equity and want to use it to supplement retirement income. If managed correctly, reverse mortgages can be helpful. But in recent years, reverse mortgage defaults and foreclosures have dramatically increased, putting many senior homeowners at risk of displacement.',
    paragraphThree: 'Through the Initiative, we connected Judith to a wide array of services so that she could better insulate her home, get a space heater to prepare for winter, and get reverse mortgage counseling. She feels optimistic about the future, and she’s thankful for her home. “I’ve had my home for over 20 years,” she says. “I have my own space, and as a woman, it feels good to have something of my own.”'
  },
  storyFive: {
    title: 'Helping Homeowners Where They Are',
    heroImage: heroHelp,
    pullQuote: '“Community is the answer”',
    callOut: '65 East New York homeowners saw an improvement in their financial condition as a result of this counseling, ranging from receiving a loan modification, a home repair, reducing debt, to improving their credit.',
    caption: 'Center staff Rosibel at a Homeowner Help Desk event in Brooklyn',
    paragraphOne: 'Mary Ann Ellis is 63 years old and the third-generation owner of her home in Woodlawn, Queens. Over the years, she has cultivated a small community that includes family members, handymen, and tenants, all of whom lovingly care for the home. But after Mary Ann was let go from her job and her medical expenses shot up, she began to struggle to pay her property taxes.',
    paragraphTwo: 'Knowing she needed help to keep from losing her family home, Mary Ann found her way to our Homeowner Help Desk last year. Created as a pilot program in East New York, Brooklyn, to bring the services of the Center and our partners into the community as a physical pop-up, it has since expanded beyond its roots. These days, the Homeowner Help Desk can be found in different neighborhoods, where it is staffed by housing counselors, attorneys, or paralegals. Homeowners who stop by can get quick on-the-spot advice or referrals to longer-term solutions. In 2018, the Homeowner Help Desk reached 760 homeowners through outreach at events, providing over 330 individual counseling sessions.',
    paragraphThree: 'At one Homeowner Help Desk event, Mary Ann received help from a Center staff member who did some initial calculations so that she could get back on track financially. She also received a referral to free services to help with her property taxes. Now in addition to her handymen, her tenants, and her repairmen, Mary Ann counts the Center as part of her network. “Community,” she says, “is the answer.”'
  }
};

export default content;
