interface Keys {
  [key: string]: any
}

const content: Keys = {
  impact: [
    {
      title: 'Saving Homes Sustains Communities',
      url: '/2018/impact/foreclosure-help'
    },
    {
      title: 'Homeowners Are Climate Justice Heroes',
      url: '/2018/impact/energy-efficiency-programs'
    },
    {
      title: 'Counseling Makes the Difference in Disaster Recovery',
      url: '/2018/impact/temporary-housing-services'
    },
    {
      title: 'Fighting for Seniors to Age in Community',
      url: '/2018/impact/helping-senior-homeowners'
    },
    {
      title: 'Helping Homeowners Where They Are',
      url: '/2018/impact/homeowner-help-desk'
    }
  ],
  about: [
    {
      url: '/2018/about/financials',
      title: 'Financials'
    },
    {
      url: '/2018/about/staff',
      title: 'Staff'
    },
    {
      url: '/2018/about/funders',
      title: 'Funders'
    },
    {
      url: '/2018/about/partners',
      title: 'Partners'
    }
  ]
};

export default content;
