interface Styles {
  [key: string]: any
}

const variables:Styles = {
  fontSizes: {
    smallest: '1.167em',
    smaller: '1.5em',
    small: '1.75em',
    medSmall: '2.333em',
    med: '2.5em',
    medLarge: '3em',
    large: '3.417em',
    larger: '4em',
    largest: '5.667em'
  },
  colors: {
    brandTeal: '#0B7E88',
    brandOrange: '#F15A29',
    brandYellow: '#F1D02B',
    brandGreen: '#6DBF5D',
    brandBlue: '#4A8DCB',
    brandPurple: '#6B53A3',
    darkTeal: '#0A585E',
    darkPurple: '#400A75',
    darkBlue: '#2375C0',
    darkGreen: '#58AF43',
    lightGray: '#F4F4F5',
    lightPurple: '#E8DFFF',
    gray: '#D6D6D6',
    darkGray: '#939597',
    black: '#252525'
  },
  media: {
    phone: '414px',
    tablet: '768px',
    desktop: '1024px',
    largeDesktop: '1400px'
  }
};

export default variables;
