import christieImg from '../images/headshots/christie.jpg';
import howardImg from '../images/headshots/howie.jpg';
import natashaImg from '../images/headshots/natasha.jpg';
import lusheenaImg from '../images/headshots/lusheena.jpg';
import jessicaImg from '../images/headshots/jess.jpg';
import lucyImg from '../images/headshots/lucy.jpg';
import lisaImg from '../images/headshots/lisa.jpg';
import josephImg from '../images/headshots/joseph.jpg';
import cristianImg from '../images/headshots/cristian.jpg';
import carolineImg from '../images/headshots/caroline.jpg';
import bradenImg from '../images/headshots/braden.jpg';
import nicoleImg from '../images/headshots/nicole.jpg';
import zarImg from '../images/headshots/zar.jpg';
import chrisImg from '../images/headshots/chris.jpg';


interface BoroughContent {
  name: string,
  partnerNames: Array<string>
}

interface BoardContent {
  title: string,
  names: Array<string>
}

interface LeadershipContent {
  image: string,
  title: string,
  name: string
}

interface FinancialsContent {
  title: string
}

interface StaffContent {
  title: string,
  subTitleBoard: string,
  subTitleLeadership: string,
  board: Array<BoardContent>,
  leadership: Array<LeadershipContent>
}

interface PartnersContent {
  title: string,
  sort: string,
  alpha: string,
  borough: string,
  boroughs: Array<BoroughContent>
}

interface FundersContent {
  title: string,
  subHeader: string,
  funderList: Array<string>
}

interface Keys {
  financials: FinancialsContent,
  staff: StaffContent,
  partners: PartnersContent,
  funders: FundersContent,
  [key: string]: any
}

const content: Keys = {
  financials: {
    title: 'OUR 2018 FINANCIALS'
  },
  staff: {
    title: 'OUR STAFF',
    subTitleBoard: 'BOARD',
    subTitleLeadership: 'LEADERSHIP',
    board: [
      {
        title: 'Board Chair:',
        names: ['Herbert Sturz']
      },
      {
        title: 'Ex Officio:',
        names: [
          'Donovan Richards',
          'Louise Carroll'
        ]
      },
      {
        title: 'Members:',
        names: [
          'Colvin Grannum',
          'Judi Kende',
          'Cathleen Mahon',
          'Sam Marks',
          'Ronay Menschel',
          'Jonathan Mintz',
          'Alexa Sewell',
          'Mathew Wambua',
          'Joseph Weisbord',
          'Kenneth Zimmerman',
          'Hakim Thompson',
          'Greg Schiefelbein',
          'Eric Enderlin'
        ]
      },
      {
        title: 'CEO/Executive Director:',
        names: [
          'Christie Peale'
        ]
      }
    ],
    leadership: [
      {
        image: christieImg,
        title: 'CEO/Executive Director',
        name: 'Christie Peale'
      },
      {
        image: natashaImg,
        title: 'Chief Financial Officer',
        name: 'Natasha Pallan'
      },
      {
        image: howardImg,
        title: 'Chief Lending Officer',
        name: 'Howard Banker'
      },
      {
        image: lucyImg,
        title: 'Vice President of Homeowner Services',
        name: 'Lucy Raimes'
      },
      {
        image: jessicaImg,
        title: 'Vice President of Development and External Affairs',
        name: 'Jessica Wells-Hasan'
      },
      {
        image: lusheenaImg,
        title: 'Chief of Staff',
        name: 'Lusheena Warner'
      },
      {
        image: lisaImg,
        title: 'General Counsel',
        name: 'Lisa Lofdahl'
      },
      {
        image: josephImg,
        title: ' Deputy General Counsel',
        name: 'Joseph Sant'
      },
      {
        image: cristianImg,
        title: 'Deputy Director, Policy and Communications',
        name: 'Cristian Salazar'
      },
      {
        image: carolineImg,
        title: 'Deputy Director, Policy and Research',
        name: 'Caroline Nagy'
      },
      {
        image: bradenImg,
        title: 'Deputy Director, Programs',
        name: 'Braden Listmann'
      },
      {
        image: nicoleImg,
        title: 'Deputy Director, Homeowner Experience',
        name: 'Nicole Zajdman'
      },
      {
        image: zarImg,
        title: 'Deputy Director, Lending',
        name: 'Zar Tun'
      },
      {
        image: chrisImg,
        title: 'Deputy Director, Systems and Vendor Management',
        name: 'Chris Sevigny'
      }
    ]
  },
  partners: {
    title: 'OUR PARTNERS',
    boroughs: [
      {
        name: 'Bronx',
        partnerNames: [
          'Bronx Legal Services',
          'The Bronx Neighborhood Housing Services CDC'
        ]
      },
      {
        name: 'Brooklyn',
        partnerNames: [
          'Bridge Street Development Corporation',
          'Brooklyn Legal Services',
          'Brooklyn Legal Services Association A',
          'Brooklyn Neighborhood Services',
          'Brooklyn Volunteer Lawyers Project',
          'CAMBA',
          'Community Organization of Southern Brooklyn',
          'Cypress Hills Local Development Corporation',
          'Grow Brooklyn',
          'IMPACCT Brooklyn',
          'Neighborhood Housing Services of Brooklyn CDC',
          'Neighbors Helping Neighbors, Inc.'
        ]
      },
      {
        name: 'Manhattan',
        partnerNames: [
          'City Bar Justice Center',
          'Housing and Family Services of Greater NY',
          'Legal Aid Society',
          'MHANY Management, Inc.',
          'Mobilization for Justice',
          'Neighborhood Housing Services of New York City',
          'New York Legal Assistance Group'
        ]
      },
      {
        name: 'Queens',
        partnerNames: [
          'Chhaya Community Development Corporation',
          'JASA – Legal Services for the Elderly in Queens',
          'Margert Community Corporation',
          'Neighborhood Housing Services of Jamaica',
          'Neighborhood Housing Services of Queens',
          'Queens Legal Services',
          'Queens Volunteer Lawyers Project'
        ]
      },
      {
        name: 'Staten Island',
        partnerNames: [
          'Northfield Community Local Development Corporation',
          'Staten Island Legal Services'
        ]
      }
    ],
    sort: 'Sort By',
    alpha: 'A-Z',
    borough: 'Borough'
  },
  funders: {
    title: 'OUR FUNDERS',
    subHeader: 'The Center is grateful to our funders for helping us reach and serve vulnerable New Yorkers every day. Their commitment to the Center and our work will have a lasting impact on New York’s residents and communities.',
    funderList: [
      'Altman Foundation',
      'American Red Cross',
      'Astoria Federal Savings',
      'Bank of America',
      'BNB Bank',
      'Burchman Terrio Quist, LLC',
      'BNY Mellon',
      'Booth Ferris Foundation',
      'Capital One Foundation',
      'Citi Community Development and Citi Foundation',
      'Community Restoration Fund',
      'Deutsche Bank',
      'Empire State Relief Fund',
      'Enterprise Community Partners, Inc.',
      'Fannie Mae',
      'F.B. Heron Foundation',
      'Federal Home Loan Bank of NY',
      'Ford Foundation',
      'Freddie Mac',
      'Goldman Sachs (formerly Goldman Sachs Gives)',
      "Governor's Office of Storm Recovery",
      'Habitat for Humanity NYC',
      'HSBC Bank USA',
      'International Advertising Association',
      'The JPM Chase & Co. Foundation',
      'JPMorgan Chase & Co.',
      'Living Cities',
      'Local Initiatives Support Corporation (LISC)',
      'M&T Bank',
      "Mayor's Fund to Advance New York City",
      'Merrill Lynch',
      'Mizuho USA Foundation',
      'Mr. Cooper Home Loans',
      'Morgan Stanley',
      'NeighborWorks America',
      'New York City Council',
      'New York City Human Resources Administration',
      'New York Community Trust',
      'NYC Department of Housing Preservation & Development',
      'NYC Housing and Neighborhood Recovery Donors Collaborative',
      'NYC Housing Recovery Office',
      'New York State CDFI',
      'New York State Division of Housing and Community Renewal',
      'New York State Energy Research & Development (NYSERDA)',
      'New York State Office of the Attorney General',
      "New York Women's Foundation",
      'Ocwen Financial Corporation',
      'Open Society Foundations',
      'Paulson & Co.',
      'Parks Municipal Consulting',
      'Robin Hood Foundation',
      'Rockefeller Foundation',
      'Rockefeller Philanthropy Advisors - Durst Family Foundation',
      'Santander Bank',
      'Seth Sprague Educational and Charitable Foundation',
      'State Bank of India',
      'Sterling Bank',
      'United Way of New York City',
      'Union Bank',
      'Wachovia Wells Fargo Foundation',
      'Washington Mutual',
      'Wells Fargo',
      'Wells Fargo Housing Philanthropy (formerly Wells Fargo Housing Foundation)'
    ]
  }
};

export default content;
