import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './Home';
import AboutFinancials from './AboutFinancials';
import AboutStaff from './AboutStaff';
import AboutPartners from './AboutPartners';
import AboutFunders from './AboutFunders';
import ImpactStories from './ImpactStories';

const AppRouter: React.FC = () => (
  <Switch>
    <Route exact path="/2018">
      <Home />
    </Route>
    <Route path="/2018/about/financials">
      <AboutFinancials />
    </Route>
    <Route path="/2018/about/staff">
      <AboutStaff />
    </Route>
    <Route path="/2018/about/partners">
      <AboutPartners />
    </Route>
    <Route path="/2018/about/funders">
      <AboutFunders />
    </Route>
    <Route path="/2018/impact/foreclosure-help">
      <ImpactStories story="storyOne" />
    </Route>
    <Route path="/2018/impact/energy-efficiency-programs">
      <ImpactStories story="storyTwo" />
    </Route>
    <Route path="/2018/impact/temporary-housing-services">
      <ImpactStories story="storyThree" />
    </Route>
    <Route path="/2018/impact/helping-senior-homeowners">
      <ImpactStories story="storyFour" />
    </Route>
    <Route path="/2018/impact/homeowner-help-desk">
      <ImpactStories story="storyFive" />
    </Route>
  </Switch>
);

export default AppRouter;
