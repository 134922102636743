import React from 'react';
import styled from 'styled-components';
import styleVars from './styles/variables';
import TabBar from './TabBar';
import PullQuote from './PullQuote';
import BoxCallOut from './BoxCallOut';
import Paragraph from './Paragraph';
import ContactButton from './ContactButton';
import ImpactStoryNav from './ImpactStoryNav';
import background from './images/stories-teal-grid.svg';
import contactBtn from './images/contact-btn-teal.svg';
import content from './content/impactStories';

const ImpactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${styleVars.colors.lightGray};
  padding-bottom: 3rem;
  position: relative;
  align-items: center;
`;

const HeaderBackground = styled.div`
  position: absolute;
  width: 100vw;
  height: 70vh;
  background-image: url(${background});
  background-size: cover;

  @media (max-width: ${styleVars.media.desktop}) {
    height: 30rem;
  }
`;

const Title = styled.h2`
  z-index: 2;
  color: white;
  padding-top: 5%;
  font-family: Whitney-Bold;
  max-width: 45rem;
  margin: 0 3rem;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 60rem;
  margin: 3rem 3rem 0 3rem;
`;

const VideoWrapper = styled.div`
  position: relative;
  z-index: 2;
  height: 34rem;
  max-width: 60rem;

  @media (max-width: ${styleVars.media.tablet}) {
    padding: 0 0 56.25% 0;
    height: 0;
  }
`;

const HeroVideo = styled.iframe`
  height: 100%;
  width: 100%;

  @media (max-width: ${styleVars.media.tablet}) {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const HeroImage = styled.img`
  z-index: 2;
  width: 100%;
`;

const Caption = styled.div`
  font-family: Whitney-Book;
  font-size: ${styleVars.fontSizes.smallest};
  color: ${styleVars.colors.darkGray};
  margin-top: 0.5rem;
  z-index: 2;
`;

const ImpactStories: React.FC<{story: string}> = ({ story }) => (
  <>
    <TabBar styleType="impact" />
    <ImpactWrapper>
      <HeaderBackground />
      <Title>
        {content[story].title}
      </Title>
      <ContentWrapper>
        {
          story === 'storyOne' ? (
            <VideoWrapper>
              <HeroVideo
                src="https://www.youtube.com/embed/XsseSWgufro"
                frameBorder="0"
                allow="accelerometer; encrypted-media; gyroscope;"
                allowFullScreen
              />
            </VideoWrapper>
          ) : <HeroImage src={content[story].heroImage} alt="Hero Image" />
        }
        <Caption>{content[story].caption}</Caption>
        <Paragraph content={content[story].paragraphOne} />
        <PullQuote content={content[story].pullQuote} />
        <Paragraph content={content[story].paragraphTwo} />
        <BoxCallOut content={content[story].callOut} />
        <Paragraph content={content[story].paragraphThree} />
        <ImpactStoryNav />
      </ContentWrapper>
      <ContactButton button={contactBtn} />
    </ImpactWrapper>
  </>
);

export default ImpactStories;
