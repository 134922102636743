import React from 'react';
import styled from 'styled-components';
import styleVars from './styles/variables';

const Wrapper = styled.h3`
  color: white;
  text-align: center;
  font-family: WhitneyCond-Semibold;
  margin: 5rem 0;

  span {
    width: fit-content;
    padding: 0.25rem;
    background-color: ${styleVars.colors.brandOrange};
  }
`;

const StatsHeader: React.FC<{ title?: string }> = (
  { title }
) => (
  <Wrapper>
    <span>{title}</span>
  </Wrapper>
);

export default StatsHeader;
