import React, { useContext } from 'react';
import styled from 'styled-components';
import ModalContext from './context';

const Wrapper = styled.div`
  position: fixed;
  bottom: 4%;
  right: 2em;
  z-index: 2;

  img {
    height: 3.5em;
  }

  &:hover {
    cursor: pointer;
    filter: brightness(120%);
  }
`;

const ContactButton: React.FC<{ className?: string, button: string }> = (
  { className, button }
) => {
  const { setModalActive } = useContext(ModalContext);
  return (
    <Wrapper className={className}>
      <img
        onClick={() => setModalActive(true)}
        src={button}
        alt="Contact Button"
      />
    </Wrapper>
  );
};


export default ContactButton;
