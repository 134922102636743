import React from 'react';
import { Switch, Route } from 'react-router-dom';
import SelectReport from './SelectReport';
import App2018 from './2018/App';
import App2019 from './2019/App';
import TermsAndConditions from './TermsAndConditions';
import NoMatch from './NoMatch';

const AppRouter: React.FC = () => (
  <Switch>
    <Route exact path="/">
      <SelectReport />
    </Route>
    <Route path="/2018">
      <App2018 />
    </Route>
    <Route path="/2019">
      <App2019 />
    </Route>
    <Route path="/terms-and-conditions">
      <TermsAndConditions />
    </Route>
    <Route>
      <NoMatch />
    </Route>
  </Switch>
);

export default AppRouter;
