import React from 'react';
import styled from 'styled-components';
import dropdown from './images/dropdown.svg';
import blueDropdown from './images/dropdown-blue.svg';
import styleVars from './styles/variables';

interface Props {
  text: string;
  nextId: string;
  relativePosition?: boolean;
  color?: string;
}

const ContentWrapper = styled.div<{
  relativePosition: boolean;
  color?: string;
}>`
  position: ${(props) => (props.relativePosition ? 'relative' : 'absolute')};
  bottom: 3%;
  left: 0;
  right: 0;
  margin: 0 auto;

  a {
    margin: 0 auto;
    width: fit-content;
    font-size: 1.5em;
    text-decoration: none;
    color: ${(props) =>
      props.color === 'blue' ? styleVars.colors.blue : 'white'};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: WhitneyCond-Semibold;

    div {
      padding-bottom: 15px;
    }

    img {
      height: 17px;
    }
  }
`;

const NextSection: React.FC<Props> = ({
  text,
  nextId,
  relativePosition,
  color
}) => (
  <ContentWrapper relativePosition={relativePosition || false} color={color}>
    <a href={`#${nextId}`}>
      <div>{text}</div>
      <img src={color === 'blue' ? blueDropdown : dropdown} alt="down arrow" />
    </a>
  </ContentWrapper>
);

export default NextSection;
