import React from 'react';
import styled from 'styled-components';
import styleVars from './styles/variables';

const ParagraphStyling = styled.div`
  color: black;
  margin: 2.25rem 10% 0 10%;
  font-family: Whitney-Book;
  font-size: 1.5rem;
  line-height: 1.4;

  @media (max-width: ${styleVars.media.desktop}) {
    margin: 2.25rem 0 0 0;
    font-size: 1.75rem;
  }
`;

const Paragraph: React.FC<{ content: string }> = ({ content }) => (
  <ParagraphStyling>{content}</ParagraphStyling>
);

export default Paragraph;
