interface Keys {
  [key: string]: any;
}

const content: Keys = {
  impact: [
    {
      title: 'Leading with Systems Change and Advocacy',
      url: '/2019/highlights/change-and-advocacy'
    },
    {
      title: 'Equity and Innovation in Lending',
      url: '/2019/highlights/equity-innovatiation-lending'
    },
    {
      title: 'Helping Homeowners Where They Are',
      url: '/2019/highlights/helping-homeowners'
    },
    {
      title: 'Deed Scams Spotlighted in the NY Times',
      url: '/2019/highlights/deed-scams-spotlight'
    }
  ],
  about: [
    {
      url: '/2019/about/financials',
      title: 'Financials'
    },
    {
      url: '/2019/about/staff',
      title: 'Staff'
    },
    {
      url: '/2019/about/funders',
      title: 'Funders'
    },
    {
      url: '/2019/about/partners',
      title: 'Partners'
    }
  ]
};

export default content;
