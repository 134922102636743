import React, { useState } from 'react';
import styled from 'styled-components';
import styleVars from './styles/variables';
import content from './content/about';
import TabBar from './TabBar';

const Wrapper = styled.div`
  background-color: ${styleVars.colors.brandPurple};
  color: #fff;
`;

const Header = styled.h2`
  padding-top: 5%;
  margin: 0 5%;
  border-bottom: 1px solid white;
  font-family: WhitneyCond-Light;
`;

const BodyWrapper = styled.div`
  display: flex;
  padding: 3rem 5% 5% 5%;

  @media (max-width: ${styleVars.media.phone}) {
    flex-direction: column;
  }
`;

const FilterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 3rem;
  padding-right: 5%;

  @media (max-width: ${styleVars.media.tablet}) {
    justify-content: center;
  }
`;

const FilterButton = styled.div`
  &:first-child {
    margin-right: 2%;
  }

  @media (max-width: ${styleVars.media.tablet}) {
    font-size: ${styleVars.fontSizes.smaller};
  }
`;

const PartnerList = styled.ul<{formatByColumn: boolean}>`
  display: flex;
  flex-flow: ${(props) => (props.formatByColumn ? 'column wrap' : 'row wrap')};
  padding-left: 0;
  padding-right: 5px;
  margin: 0;
  list-style: none;
  font-family: WhitneyCond-Book;
  font-size: ${styleVars.fontSizes.smallest};

  @media (max-width: ${styleVars.media.phone}) {
    flex-flow: column wrap;
  }
`;

const PartnerListHeader = styled.li`
  padding: 2% 0;
  font-family: WhitneyCond-Semibold;

  @media (max-width: ${styleVars.media.tablet}) {
    font-size: ${styleVars.fontSizes.smaller};
  }

  @media (max-width: ${styleVars.media.phone}) {
    padding-left: 0;
  }
`;

const PartnerItem = styled.li<{formatByColumn: boolean}>`
  padding-left: ${(props) => (props.formatByColumn ? '0' : '2%')}
  padding-bottom: ${(props) => (props.formatByColumn ? '2%' : '1%')};
  line-height: 1.4;
  width: ${(props) => (props.formatByColumn ? 'auto' : '200px')};

  @media (max-width: ${styleVars.media.tablet}) {
    font-size: ${styleVars.fontSizes.small};
  }

  @media (max-width: ${styleVars.media.phone}) {
    font-size: ${styleVars.fontSizes.medSmall};
  }
`;

const AboutPartners: React.FC = () => {
  const [filterByBorough, setFilterByBorough] = useState<boolean>(false);

  // TODO refactor the two methods below-
  const renderBoroughPartners = () => content.partners.boroughs.map((borough) => {
    const partners = borough.partnerNames.map((partner) => (
      <PartnerItem formatByColumn={filterByBorough}>
        {partner}
      </PartnerItem>
    ));

    return (
      <PartnerList formatByColumn={filterByBorough}>
        <PartnerListHeader>
          {borough.name}
        </PartnerListHeader>
        {partners}
      </PartnerList>
    );
  });

  const renderAlphaPartners = () => {
    const partnerList:string[] = [];
    content.partners.boroughs.map((borough) => borough.partnerNames.map((partner) => (
      partnerList.push(partner)
    )));
    const sortedPartners = partnerList.sort();
    const partners = sortedPartners.map((partner) => (
      <PartnerItem formatByColumn={filterByBorough}>
        {partner}
      </PartnerItem>
    ));

    return (
      <PartnerList formatByColumn={filterByBorough}>
        {partners}
      </PartnerList>
    );
  };

  const handleClick = () => {
    setFilterByBorough((prevState) => !prevState);
  };

  return (
    <>
      <TabBar styleType="about" />
      <Wrapper>
        <Header>
          {content.partners.title}
        </Header>
        <FilterWrapper>
          <FilterButton>
            {content.partners.sort}
          </FilterButton>
          <FilterButton>
            <button
              className={!filterByBorough ? 'selected' : ''}
              type="button"
              onClick={handleClick}
            >
              {content.partners.alpha}
            </button>
          </FilterButton>
          <FilterButton>
            <button
              className={filterByBorough ? 'selected' : ''}
              type="button"
              onClick={handleClick}
            >
              {content.partners.borough}
            </button>
          </FilterButton>
        </FilterWrapper>
        <BodyWrapper>
          {filterByBorough ? renderBoroughPartners() : renderAlphaPartners()}
        </BodyWrapper>
      </Wrapper>
    </>
  );
};

export default AboutPartners;
