interface Keys {
  [key: string]: any;
}

const content: Keys = {
  contact: {
    info: 'Dial 311 and ask for the Center for NYC Neighborhoods',
    phone: 'Or call us directly at 646-786-0888',
    email: 'Email us at: info@cnycn.org'
  },
  impactTitle: 'Highlights',
  impactLinks: [
    {
      title: 'Leading with Systems Change and Advocacy',
      url: '/2019/highlights/change-and-advocacy'
    },
    {
      title: 'Equity and Innovation in Lending',
      url: '/2019/highlights/equity-innovatiation-lending'
    },
    {
      title: 'Helping Homeowners Where They Are',
      url: '/2019/highlights/helping-homeowners'
    },
    {
      title: 'Deed Scams Spotlighted in the NY Times',
      url: '/2019/highlights/deed-scams-spotlight'
    }
  ],
  aboutLinks: [
    {
      url: '/2019/about/financials',
      title: 'Financials'
    },
    {
      url: '/2019/about/staff',
      title: 'Staff'
    },
    {
      url: '/2019/about/partners',
      title: 'Partners'
    },
    {
      url: '/2019/about/funders',
      title: 'Funders'
    }
  ],
  privacy: 'Privacy Policy',
  terms: 'Terms & Conditions',
  visit: 'Visit:'
};

export default content;
