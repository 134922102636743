interface Keys {
  header: string;
  link: string;
}

const content: Keys = {
  header: "Read More about Our Highlights from 2019",
  link: 'Go to Stories'
};

export default content;
