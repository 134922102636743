import React from 'react';
import styled from 'styled-components';
import styleVars from './styles/variables';
import content from './content/about';
import TabBar from './TabBar';
import expenseChart from './images/financials-expense-chart.svg';
import incomeChart from './images/financials-income-chart.svg';

const Wrapper = styled.div`
  background-color: ${styleVars.colors.brandPurple};
  color: #fff;
`;

const Header = styled.h2`
  padding-top: 5%;
  margin: 0 5%;
  border-bottom: 1px solid white;
  font-family: WhitneyCond-Light;
`;

const BodyWrapper = styled.div`
  display: flex;
  padding: 5%;
  @media (max-width: ${styleVars.media.desktop}) {
     flex-direction: column;
   }
`;

const Body = styled.div`
  flex: 1 1 auto;
  text-align: center;
  padding: 5%;

  img {
    max-width: 100%;
  }
`;

const AboutFinancials: React.FC = () => (
  <>
    <TabBar styleType="about" />
    <Wrapper>
      <Header>
        {content.financials.title}
      </Header>
      <BodyWrapper>
        <Body>
          <img src={incomeChart} alt="income chart" />
        </Body>
        <Body>
          <img src={expenseChart} alt="expense chart" />
        </Body>
      </BodyWrapper>
    </Wrapper>
  </>
);

export default AboutFinancials;
