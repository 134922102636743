import React from 'react';
import styled from 'styled-components';
import WindowSizedWrapper from './WindowSizedWrapper';
import content from './content/highlightsLanding';
import styleVars from './styles/variables';
import background from './images/home-rays.svg';

const ContentWrapper = styled.div`
  height: 100%;
  color: ${styleVars.colors.blue};
`;

const TextWrapper = styled.div`
  text-align: left;
  position: relative;
  top: -1rem;

  @media (min-width: ${styleVars.media.tablet}) {
    left: -4rem;
  }
`;

const Header = styled.h3`
  margin: 0 13%;

  @media (max-width: ${styleVars.media.tablet}) {
    margin: 0 1%;
  }
`;

const StoriesLink = styled.a`
  display: inline-block;
  padding: 2rem 5rem;
  margin-top: 2rem;
  background-color: ${styleVars.colors.blue};
  border-radius: 5px;
  font-size: ${styleVars.fontSizes.med};
`;

const HighlightsLanding: React.FC = () => (
  <WindowSizedWrapper
    id="to-stories"
    backgroundUrl={background}
    backgroundColor={styleVars.colors.lightBlue}
  >
    <ContentWrapper>
      <Header>{content.header}</Header>
      <StoriesLink href="/2019/highlights/change-and-advocacy">
        {content.link}
      </StoriesLink>
    </ContentWrapper>
  </WindowSizedWrapper>
);

export default HighlightsLanding;
