import React from 'react';
import styled from 'styled-components';
import WindowSizedWrapper from './WindowSizedWrapper';
import NextSection from './NextSection';
import content from './content/impactLanding';
import background from './images/home-teal-grid-1.svg';
import styleVars from './styles/variables';

const ContentWrapper = styled.div`
  height: 100%;
  color: white;
`;

const Header = styled.h3`
  margin: 0 13%;

  @media (max-width: ${styleVars.media.tablet}) {
    margin: 0 1%;
  }
`;

const Highlight = styled.span`
  background-color: ${styleVars.colors.brandOrange};
  padding: 0 1%;
`;

const ImpactLanding: React.FC = () => (
  <WindowSizedWrapper id="impact" backgroundUrl={background}>
    <ContentWrapper>
      <Header>
        <span>{content.header[0]}</span>
        <Highlight>{content.header[1]}</Highlight>
        <span>{content.header[2]}</span>
      </Header>
    </ContentWrapper>
    <NextSection text={content.next} nextId="stats-2018" />
  </WindowSizedWrapper>
);

export default ImpactLanding;
