interface Keys {
  header: string;
  successHeader: string;
  errorHeader: string;
  description: string;
  sucessDescription: string;
  errorDescription: string;
  loadingHeader: string;
  name: string;
  phone: string;
  email: string;
  subject: string;
  message: string;
  submit: string;
  subjectOptionDefault: string;
  subjectOptionDonate: string;
  subjectOptionContact: string;
  subjectOptionService: string;
}

const content: Keys = {
  header: 'Contact',
  successHeader: 'Thanks for getting in touch with the Center!',
  errorHeader: "We're sorry - something went wrong!",
  loadingHeader: 'Loading...',
  description: 'Fill out the form below',
  sucessDescription:
    "We'll respond to your inquiry via email or phone call within 24 hours.",
  errorDescription:
    'To get in contact, please email us at <a href="mailto:fundraising@cnycn.org">fundraising@cnycn.org</a> or call us at <a href="tel:646-786-0888">646-786-0888</a>',
  name: 'Name',
  email: 'Email',
  phone: 'Phone Number',
  subject: "I'd like to learn more about:",
  subjectOptionDefault: 'Choose an option',
  subjectOptionContact: "Learning more about the Center's work",
  subjectOptionDonate: 'Making a donation to the Center',
  subjectOptionService:
    'Getting a referral to a housing counseling or legal services partner',
  message: 'Message',
  submit: 'Submit'
};

export default content;
