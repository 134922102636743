import React from 'react';
import styled from 'styled-components';
import WindowSizedWrapper from './WindowSizedWrapper';
import NextSection from './NextSection';
import content from './content/impactLanding';
import background from './images/home-rays.svg';
import styleVars from './styles/variables';

const ContentWrapper = styled.div`
  height: 100%;
  color: ${styleVars.colors.blue};
`;

const Header = styled.h3`
  margin: 0 13%;

  @media (max-width: ${styleVars.media.tablet}) {
    margin: 0 1%;
  }
`;

const Highlight = styled.span`
  color: white;
  background-color: ${styleVars.colors.blue};
  padding: 0 1%;
`;

const ImpactLanding: React.FC = () => (
  <WindowSizedWrapper
    id="impact"
    backgroundUrl={background}
    backgroundColor={styleVars.colors.lightBlue}
  >
    <ContentWrapper>
      <Header>
        <span>{content.header[0]}</span>
        <br />
        <Highlight>{content.header[1]}</Highlight>
        <br />
        <span>{content.header[2]}</span>
      </Header>
    </ContentWrapper>
    <NextSection text={content.next} nextId="stats-2019" color="blue" />
  </WindowSizedWrapper>
);

export default ImpactLanding;
