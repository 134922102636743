import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import styleVars from './styles/variables';
import content from './content/footer';
import Logo from './images/CNYCN-logo.svg';
import Twitter from './images/social-icon-twitter.svg';
import Facebook from './images/social-icon-facebook.svg';
import Instagram from './images/social-icon-instagram.svg';

const FooterDiv = styled.footer`
  display: flex;
  font-family: Whitney-Book;
  background-color: #fff;
  color: ${styleVars.colors.brandTeal};
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-around;
  flex: 2;
  border-right: 2px solid ${styleVars.colors.gray};

  @media (max-width: ${styleVars.media.tablet}) {
    flex-direction: column;
    justify-content: center;
    flex: 1;
  }
`;

const FooterContentStories = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 5%;
`;

const FooterStoryHeader = styled.div`
  font-family: Whitney-Semibold;

  @media (max-width: ${styleVars.media.tablet}) {
    display: none;
  }
`;

const FooterStoryHeaderMobile = styled(Link)`
  font-family: Whitney-Semibold;
  color: ${styleVars.colors.brandTeal};
  display: none;

  @media (max-width: ${styleVars.media.tablet}) {
    display: block;
  }
`;

const FooterContentLinks = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 5%;

  @media (max-width: ${styleVars.media.tablet}) {
    justify-content: center;
  }
`;

const FooterStoryLink = styled(Link)`
  color: ${styleVars.colors.brandTeal};
  padding-top: 2%;

  @media (max-width: ${styleVars.media.tablet}) {
    display: none;
  }

  &:hover {
    filter: brightness(120%);
  }
`;

const FooterAboutLink = styled(Link)`
  font-family: Whitney-Semibold;
  color: ${styleVars.colors.brandTeal};
  padding-top: 20%;

  @media (max-width: ${styleVars.media.tablet}) {
    padding-top: 15%;
  }

  &:hover {
    filter: brightness(120%);
  }
`;

const SocialMedia = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 5%;

  @media (max-width: ${styleVars.media.tablet}) {
    flex: 2;
  }
`;

const SocialMediaButtons = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 5%;
  padding-top: 2%;

  p {
    @media (max-width: ${styleVars.media.tablet}) {
      display: none;
    }
  }
`;

const SocialMediaItem = styled.div`
  padding-left: 5%;
  padding-top: 2%;
`;

const SocialMediaLink = styled.div`
  padding-left: 5%;
  padding-top: 2%;

  a {
    width: 100%;
    height: 100%;
  }

  .small-img {
    width: 35px;
    height: 35px;
  }

  &:hover {
    filter: brightness(120%);
  }

  @media (max-width: ${styleVars.media.tablet}) {
    padding-left: 10%;
  }
`;

const Footer: React.FC = () => (
  <>
    <FooterDiv>
      <FooterContent>
        <FooterContentStories>
          <FooterStoryHeader>{content.impactTitle}</FooterStoryHeader>
          <FooterStoryHeaderMobile to="/2018/impact/foreclosure-help">
            {content.impactTitle}
          </FooterStoryHeaderMobile>
          {content.impactLinks.map((link: any) => (
            <FooterStoryLink to={link.url}>{link.title}</FooterStoryLink>
          ))}
        </FooterContentStories>
        <FooterContentLinks>
          {content.aboutLinks.map((link: any) => (
            <FooterAboutLink to={link.url}>{link.title}</FooterAboutLink>
          ))}
        </FooterContentLinks>
      </FooterContent>
      <SocialMedia>
        <SocialMediaItem>
          <img src={Logo} alt="Center Logo" />
        </SocialMediaItem>
        <SocialMediaButtons>
          <p>{content.visit}</p>
          <SocialMediaLink>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fannualreport.cnycn.org/2018%2F&amp;src=sdkpreparse"
            >
              <img className="small-img" src={Facebook} alt="Facebook" />
            </a>
          </SocialMediaLink>
          <SocialMediaLink>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.twitter.com/intent/tweet?text=Last%20year%2C%20we%20funded%20more%20than%20%2483M%20in%20zero-interest%20loans%20to%20keep%20homeowners%20in%20their%20homes.%20Check%20out%20%40CNYCN%E2%80%99s%20annual%20report%21%20&hashtags=homeownershipnyc&url=https://annualreport.cnycn.org/2018"
            >
              <img className="small-img" src={Twitter} alt="Facebook" />
            </a>
          </SocialMediaLink>
          <SocialMediaLink>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/nyneighborhoods/"
            >
              <img className="small-img" src={Instagram} alt="Instagram" />
            </a>
          </SocialMediaLink>
        </SocialMediaButtons>
        <SocialMediaItem>{content.contact.info}</SocialMediaItem>
        <SocialMediaItem>{content.contact.phone}</SocialMediaItem>
        <SocialMediaItem>{content.contact.email}</SocialMediaItem>
      </SocialMedia>
    </FooterDiv>
  </>
);

export default Footer;
